export default function ResearchIllustration() {
  return (
    <svg
      width="132"
      height="170"
      viewBox="0 0 132 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M130.156 0H1.84431C1.35534 0.00101765 0.886542 0.36581 0.540784 1.01433C0.195026 1.66286 0.000542557 2.54215 0 3.4593V166.541C0.000543253 167.458 0.195034 168.337 0.540792 168.986C0.88655 169.634 1.35534 169.999 1.84431 170H130.156C130.645 169.999 131.113 169.634 131.459 168.986C131.805 168.337 131.999 167.458 132 166.541V3.4593C131.999 2.54215 131.805 1.66286 131.459 1.01433C131.113 0.36581 130.645 0.00101765 130.156 0ZM131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path d="M131 14.0828H1V15.0887H131V14.0828Z" fill="#3F3D56" />
      <path
        d="M9.19759 10.6733C10.8902 10.6733 12.2624 9.30807 12.2624 7.62397C12.2624 5.93988 10.8902 4.57465 9.19759 4.57465C7.50496 4.57465 6.13281 5.93988 6.13281 7.62397C6.13281 9.30807 7.50496 10.6733 9.19759 10.6733Z"
        fill="#3F3D56"
      />
      <path
        d="M17.6253 10.6733C19.318 10.6733 20.6901 9.30807 20.6901 7.62397C20.6901 5.93988 19.318 4.57465 17.6253 4.57465C15.9327 4.57465 14.5605 5.93988 14.5605 7.62397C14.5605 9.30807 15.9327 10.6733 17.6253 10.6733Z"
        fill="#3F3D56"
      />
      <path
        d="M26.0492 10.6733C27.7418 10.6733 29.1139 9.30807 29.1139 7.62397C29.1139 5.93988 27.7418 4.57465 26.0492 4.57465C24.3565 4.57465 22.9844 5.93988 22.9844 7.62397C22.9844 9.30807 24.3565 10.6733 26.0492 10.6733Z"
        fill="#3F3D56"
      />
      <path
        d="M28.7709 150.18H20.2544C19.6567 150.179 19.0837 149.942 18.661 149.521C18.2384 149.099 18.0007 148.528 18 147.932V123.459C18.0007 122.863 18.2384 122.292 18.661 121.87C19.0837 121.449 19.6567 121.212 20.2544 121.211H28.7709C29.3686 121.212 29.9417 121.449 30.3643 121.87C30.7869 122.292 31.0246 122.863 31.0253 123.459V147.932C31.0246 148.528 30.7869 149.099 30.3643 149.521C29.9416 149.942 29.3686 150.179 28.7709 150.18Z"
        fill="#CCCCCC"
      />
      <path
        d="M53.75 149.882H45.25C44.6535 149.881 44.0816 149.675 43.6598 149.308C43.2379 148.941 43.0007 148.444 43 147.926V116.631C43.0007 116.112 43.2379 115.615 43.6598 115.248C44.0816 114.881 44.6535 114.675 45.25 114.675H53.75C54.3465 114.675 54.9185 114.881 55.3403 115.248C55.7621 115.615 55.9993 116.112 56 116.631V147.926C55.9993 148.444 55.7621 148.941 55.3402 149.308C54.9184 149.675 54.3465 149.881 53.75 149.882Z"
        fill="#9C73F2"
        fillOpacity="0.5"
      />
      <path
        d="M78.75 149.882H70.25C69.6535 149.881 69.0816 149.673 68.6598 149.303C68.2379 148.934 68.0007 148.432 68 147.91V107.593C68.0007 107.07 68.2379 106.569 68.6598 106.2C69.0816 105.83 69.6535 105.622 70.25 105.621H78.75C79.3465 105.622 79.9185 105.83 80.3403 106.2C80.7621 106.569 80.9993 107.07 81 107.593V147.91C80.9993 148.432 80.7621 148.934 80.3402 149.303C79.9184 149.673 79.3465 149.881 78.75 149.882Z"
        fill="#9C73F2"
      />
      <path
        d="M103.577 149.882H94.4231C93.7807 149.881 93.1648 149.607 92.7105 149.119C92.2563 148.632 92.0007 147.971 92 147.282V94.1379C92.0007 93.4487 92.2563 92.788 92.7105 92.3007C93.1648 91.8133 93.7807 91.5392 94.4231 91.5385H103.577C104.219 91.5392 104.835 91.8133 105.29 92.3007C105.744 92.788 105.999 93.4487 106 94.1379V147.282C105.999 147.971 105.744 148.632 105.289 149.119C104.835 149.607 104.219 149.881 103.577 149.882Z"
        fill="#9C73F2"
      />
      <path
        d="M28.6132 150.18H20.4121C19.7726 150.179 19.1595 149.918 18.7073 149.453C18.2551 148.989 18.0007 148.359 18 147.702V93.1957C18.0007 92.5388 18.2551 91.909 18.7073 91.4446C19.1595 90.9801 19.7726 90.7188 20.4121 90.7181H28.6132C29.2527 90.7188 29.8658 90.9801 30.318 91.4446C30.7702 91.909 31.0246 92.5388 31.0253 93.1957V147.702C31.0246 148.359 30.7702 148.989 30.318 149.453C29.8658 149.918 29.2527 150.179 28.6132 150.18ZM20.4121 91.7091C20.0284 91.7095 19.6605 91.8663 19.3892 92.145C19.1179 92.4237 18.9653 92.8015 18.9648 93.1957V147.702C18.9653 148.096 19.1179 148.474 19.3892 148.753C19.6605 149.032 20.0284 149.188 20.4121 149.189H28.6132C28.9969 149.188 29.3648 149.032 29.6361 148.753C29.9074 148.474 30.0601 148.096 30.0605 147.702V93.1957C30.0601 92.8015 29.9074 92.4237 29.6361 92.145C29.3648 91.8663 28.9969 91.7095 28.6132 91.7091H20.4121Z"
        fill="#3F3D56"
      />
      <path
        d="M53.7571 150.18H45.0735C44.3964 150.179 43.7473 149.918 43.2685 149.453C42.7897 148.989 42.5203 148.359 42.5195 147.702V93.1957C42.5203 92.5388 42.7897 91.909 43.2685 91.4446C43.7473 90.9801 44.3964 90.7188 45.0735 90.7181H53.7571C54.4342 90.7188 55.0833 90.9801 55.5621 91.4446C56.0409 91.909 56.3103 92.5388 56.311 93.1957V147.702C56.3103 148.359 56.0409 148.989 55.5621 149.453C55.0833 149.918 54.4342 150.179 53.7571 150.18ZM45.0735 91.7091C44.6672 91.7095 44.2777 91.8663 43.9904 92.145C43.7032 92.4237 43.5416 92.8015 43.5411 93.1957V147.702C43.5416 148.096 43.7032 148.474 43.9904 148.753C44.2777 149.032 44.6672 149.188 45.0735 149.189H53.7571C54.1633 149.188 54.5528 149.032 54.8401 148.753C55.1274 148.474 55.289 148.096 55.2894 147.702V93.1957C55.289 92.8015 55.1274 92.4237 54.8401 92.145C54.5528 91.8663 54.1633 91.7095 53.7571 91.7091H45.0735Z"
        fill="#3F3D56"
      />
      <path
        d="M78.2746 150.18H69.5911C68.914 150.179 68.2648 149.918 67.786 149.453C67.3072 148.989 67.0379 148.359 67.0371 147.702V93.1957C67.0379 92.5388 67.3072 91.909 67.786 91.4446C68.2648 90.9801 68.914 90.7188 69.5911 90.7181H78.2746C78.9518 90.7188 79.6009 90.9801 80.0797 91.4446C80.5585 91.909 80.8278 92.5388 80.8286 93.1957V147.702C80.8278 148.359 80.5585 148.989 80.0797 149.453C79.6009 149.918 78.9518 150.179 78.2746 150.18ZM69.5911 91.7091C69.1848 91.7095 68.7953 91.8663 68.508 92.145C68.2207 92.4237 68.0591 92.8015 68.0587 93.1957V147.702C68.0591 148.096 68.2207 148.474 68.508 148.753C68.7953 149.032 69.1848 149.188 69.5911 149.189H78.2746C78.6809 149.188 79.0704 149.032 79.3577 148.753C79.645 148.474 79.8066 148.096 79.807 147.702V93.1957C79.8066 92.8015 79.645 92.4237 79.3577 92.145C79.0704 91.8663 78.6809 91.7095 78.2746 91.7091H69.5911Z"
        fill="#3F3D56"
      />
      <path
        d="M103.407 149.882H94.5926C93.9052 149.881 93.2463 149.62 92.7602 149.157C92.2742 148.693 92.0008 148.064 92 147.409V93.0054C92.0008 92.3498 92.2742 91.7212 92.7602 91.2576C93.2463 90.794 93.9052 90.5333 94.5926 90.5325H103.407C104.095 90.5333 104.754 90.794 105.24 91.2576C105.726 91.7212 105.999 92.3498 106 93.0054V147.409C105.999 148.064 105.726 148.693 105.24 149.157C104.754 149.62 104.095 149.881 103.407 149.882ZM94.5926 91.5217C94.1802 91.5221 93.7848 91.6786 93.4931 91.9567C93.2015 92.2349 93.0375 92.612 93.037 93.0054V147.409C93.0375 147.802 93.2015 148.179 93.4931 148.457C93.7848 148.736 94.1802 148.892 94.5926 148.892H103.407C103.82 148.892 104.215 148.736 104.507 148.457C104.798 148.179 104.963 147.802 104.963 147.409V93.0054C104.963 92.612 104.798 92.2349 104.507 91.9567C104.215 91.6786 103.82 91.5221 103.407 91.5217H94.5926Z"
        fill="#3F3D56"
      />
      <path
        d="M57.4664 74.7459C61.9376 72.5514 65.7511 69.1687 68.4955 64.9626C71.2398 60.7565 72.8109 55.8867 73.0391 50.8789C73.2673 45.8711 72.144 40.9153 69.7903 36.5463C67.4367 32.1773 63.9421 28.5611 59.6837 26.0877L47.6961 46.9737L57.4664 74.7459Z"
        fill="#9C73F2"
      />
      <path
        d="M31.3177 73.498C35.2391 75.7787 39.6466 77.0764 44.1583 77.2787C48.6699 77.4811 53.1497 76.5818 57.2095 74.659L46.8177 46.4923L31.3177 73.498Z"
        fill="#CCCCCC"
      />
      <path
        d="M59.4229 25.9363L59.6836 26.0877L32.1836 74.001L31.9229 73.8495C25.5608 70.1547 20.9081 64.0872 18.9881 56.9818C17.0681 49.8764 18.0382 42.3152 21.6849 35.9615C25.3316 29.6078 31.3563 24.9821 38.4335 23.1021C45.5108 21.222 53.0609 22.2415 59.4229 25.9363ZM31.9618 73.1759L58.8624 26.3071C52.6449 22.7876 45.3087 21.8592 38.446 23.7234C31.5833 25.5876 25.748 30.0939 22.2064 36.2644C18.6648 42.4349 17.7029 49.7715 19.5293 56.682C21.3557 63.5925 25.823 69.5193 31.9618 73.1759Z"
        fill="#3F3D56"
      />
      <path
        d="M119.429 78.357C119.126 78.8816 118.638 79.2576 118.072 79.4023C117.506 79.547 116.909 79.4485 116.412 79.1285L90.802 62.6384C90.5559 62.48 90.3418 62.2719 90.1719 62.0261C90.002 61.7803 89.8796 61.5016 89.8117 61.2059C89.7438 60.9101 89.7318 60.6032 89.7763 60.3026C89.8208 60.0019 89.921 59.7135 90.0711 59.4537C90.2212 59.1939 90.4183 58.9679 90.6511 58.7885C90.884 58.6092 91.148 58.48 91.4282 58.4083C91.7083 58.3367 91.9991 58.324 92.2839 58.371C92.5687 58.418 92.8419 58.5237 93.088 58.6822L118.698 75.1722C119.195 75.4923 119.551 76.0076 119.688 76.6048C119.825 77.2021 119.732 77.8323 119.429 78.357Z"
        fill="#3F3D56"
      />
      <path
        d="M94.6631 62.0801C92.515 65.6223 89.3708 68.4442 85.6282 70.1891C81.8855 71.9339 77.7125 72.5233 73.6368 71.8827C69.5612 71.2421 65.7659 69.4003 62.731 66.5902C59.696 63.7801 57.5577 60.1278 56.5864 56.0953C55.6151 52.0629 55.8545 47.8312 57.2743 43.9356C58.694 40.04 61.2305 36.6553 64.5627 34.2095C67.895 31.7638 71.8735 30.3669 75.9951 30.1954C80.1167 30.024 84.1963 31.0857 87.718 33.2463C92.4356 36.1479 95.8162 40.8132 97.1182 46.2187C98.4202 51.6243 97.5372 57.3285 94.6631 62.0801ZM63.5025 42.9627C61.8914 45.6194 61.0998 48.6969 61.2276 51.8061C61.3555 54.9153 62.3971 57.9165 64.2208 60.4303C66.0445 62.944 68.5683 64.8574 71.4732 65.9284C74.378 66.9994 77.5333 67.18 80.5402 66.4473C83.547 65.7146 86.2704 64.1016 88.3658 61.8121C90.4612 59.5226 91.8346 56.6596 92.3122 53.5851C92.7899 50.5105 92.3504 47.3626 91.0493 44.5393C89.7483 41.716 87.644 39.3441 85.0028 37.7237C81.4597 35.5555 77.2063 34.8894 73.1756 35.8716C69.1449 36.8538 65.6661 39.404 63.5025 42.9627Z"
        fill="#3F3D56"
      />
    </svg>
  );
}
