export default function UserIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="user"
        d="M1 16.75C1 15.5565 1.47411 14.4119 2.31802 13.568C3.16193 12.7241 4.30653 12.25 5.5 12.25H14.5C15.6935 12.25 16.8381 12.7241 17.682 13.568C18.5259 14.4119 19 15.5565 19 16.75C19 17.3467 18.7629 17.919 18.341 18.341C17.919 18.7629 17.3467 19 16.75 19H3.25C2.65326 19 2.08097 18.7629 1.65901 18.341C1.23705 17.919 1 17.3467 1 16.75Z"
        stroke="#D1D1D1"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className="user"
        d="M10 7.74996C11.864 7.74996 13.375 6.23893 13.375 4.37498C13.375 2.51103 11.864 1 10 1C8.13604 1 6.625 2.51103 6.625 4.37498C6.625 6.23893 8.13604 7.74996 10 7.74996Z"
        stroke="#D1D1D1"
        strokeWidth="2"
      />
    </svg>
  )
}
