export default function CrossIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.254131 13.7459C0.0914112 13.5831 0 13.3624 0 13.1322C0 12.9021 0.0914112 12.6813 0.254131 12.5186L5.77792 6.99476L0.254131 1.47097C0.0960228 1.30727 0.00853584 1.08801 0.0105134 0.860432C0.0124911 0.632852 0.103776 0.415154 0.264705 0.254225C0.425635 0.0932951 0.643332 0.00201042 0.870913 3.28117e-05C1.09849 -0.0019448 1.31775 0.0855421 1.48145 0.243651L7.00524 5.76744L12.529 0.243651C12.6927 0.0855421 12.912 -0.0019448 13.1396 3.28117e-05C13.3671 0.00201042 13.5848 0.0932951 13.7458 0.254225C13.9067 0.415154 13.998 0.632852 14 0.860432C14.0019 1.08801 13.9145 1.30727 13.7563 1.47097L8.23256 6.99476L13.7563 12.5186C13.9145 12.6823 14.0019 12.9015 14 13.1291C13.998 13.3567 13.9067 13.5744 13.7458 13.7353C13.5848 13.8962 13.3671 13.9875 13.1396 13.9895C12.912 13.9915 12.6927 13.904 12.529 13.7459L7.00524 8.22208L1.48145 13.7459C1.31868 13.9086 1.09795 14 0.86779 14C0.637635 14 0.416901 13.9086 0.254131 13.7459Z"
        fill="white"
      />
    </svg>
  )
}
