import React, { Component } from "react";

export default class ResearchIcon extends Component {
  render() {
    return (
      <svg
        width="55"
        height="55"
        viewBox="0 0 55 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#9C73F2" />
        <path
          d="M27.5 46C25.0705 46 22.6649 45.5215 20.4204 44.5918C18.1758 43.6621 16.1364 42.2994 14.4185 40.5815C12.7006 38.8636 11.3379 36.8242 10.4082 34.5796C9.47852 32.3351 9 29.9295 9 27.5C9 25.0705 9.47852 22.6649 10.4082 20.4204C11.3379 18.1758 12.7006 16.1364 14.4185 14.4185C16.1364 12.7006 18.1758 11.3379 20.4204 10.4082C22.6649 9.47852 25.0705 9 27.5 9C32.4065 9 37.1121 10.9491 40.5815 14.4185C44.0509 17.8879 46 22.5935 46 27.5C46 32.4065 44.0509 37.1121 40.5815 40.5815C37.1121 44.0509 32.4065 46 27.5 46Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M35.8303 33.9973L32.1186 30.2854H32.0786C33.2227 28.9363 33.8008 27.1972 33.6921 25.4317C33.5834 23.6662 32.7962 22.0111 31.4953 20.8126C30.1944 19.6142 28.4805 18.9652 26.7121 19.0014C24.9437 19.0377 23.2578 19.7564 22.0071 21.0072C20.7563 22.2579 20.0377 23.9439 20.0014 25.7124C19.9652 27.4809 20.6141 29.1949 21.8125 30.4958C23.0109 31.7968 24.6659 32.584 26.4314 32.6927C28.1968 32.8014 29.9359 32.2233 31.2848 31.0791C31.2848 31.0791 31.2848 31.1077 31.2848 31.1191L34.9966 34.8311C35.0497 34.8846 35.1129 34.9271 35.1824 34.9561C35.252 34.9851 35.3267 35 35.4021 35C35.4774 35 35.5521 34.9851 35.6217 34.9561C35.6913 34.9271 35.7544 34.8846 35.8075 34.8311C35.866 34.7792 35.9133 34.7159 35.9464 34.6451C35.9795 34.5742 35.9976 34.4973 35.9998 34.4192C36.0019 34.341 35.988 34.2632 35.9588 34.1906C35.9297 34.1181 35.886 34.0523 35.8303 33.9973ZM26.8592 31.5474C25.7298 31.5474 24.6258 31.2125 23.6867 30.585C22.7476 29.9575 22.0157 29.0656 21.5835 28.0221C21.1513 26.9786 21.0382 25.8304 21.2585 24.7226C21.4789 23.6149 22.0227 22.5973 22.8214 21.7986C23.62 21 24.6375 20.4561 25.7452 20.2358C26.8529 20.0154 28.0011 20.1285 29.0445 20.5607C30.088 20.993 30.9798 21.7249 31.6073 22.664C32.2348 23.6031 32.5697 24.7073 32.5697 25.8367C32.5697 26.5867 32.422 27.3293 32.135 28.0221C31.848 28.715 31.4274 29.3445 30.8971 29.8748C30.3669 30.4051 29.7374 30.8257 29.0445 31.1127C28.3517 31.3997 27.6092 31.5474 26.8592 31.5474Z"
          fill="white"
        />
      </svg>
    );
  }
}
