import React, { Component } from "react";

export default class PrototypeIcon extends Component {
  render() {
    return (
      <svg
        width="55"
        height="55"
        viewBox="0 0 55 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#9C73F2" />
        <path
          d="M27.5 46C25.0705 46 22.6649 45.5215 20.4204 44.5918C18.1758 43.6621 16.1364 42.2994 14.4185 40.5815C12.7006 38.8636 11.3379 36.8242 10.4082 34.5796C9.47852 32.3351 9 29.9295 9 27.5C9 25.0705 9.47852 22.6649 10.4082 20.4204C11.3379 18.1758 12.7006 16.1364 14.4185 14.4185C16.1364 12.7006 18.1758 11.3379 20.4204 10.4082C22.6649 9.47852 25.0705 9 27.5 9C32.4065 9 37.1121 10.9491 40.5815 14.4185C44.0509 17.8879 46 22.5935 46 27.5C46 32.4065 44.0509 37.1121 40.5815 40.5815C37.1121 44.0509 32.4065 46 27.5 46Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M24.1797 35.4365H32.1798"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2266 37.3784H30.9736"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.2695 39H29.9267"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0701 29.7939C22.1177 29.0279 21.3463 28.0478 20.8144 26.9277C20.2825 25.8076 20.004 24.577 20.0001 23.3291C19.9801 18.8399 23.4702 15.1058 27.8102 15.0023C29.4899 14.9611 31.1395 15.468 32.5251 16.451C33.9106 17.4341 34.9618 18.8434 35.5295 20.4791C36.0972 22.1148 36.1526 23.8938 35.6879 25.5639C35.2231 27.234 34.2619 28.7104 32.9403 29.7836C32.6484 30.017 32.412 30.3164 32.249 30.6588C32.0861 31.0012 32.001 31.3776 32.0003 31.7593V32.3799C32.0003 32.5994 31.916 32.8098 31.766 32.965C31.616 33.1202 31.4125 33.2074 31.2003 33.2074H24.8002C24.588 33.2074 24.3845 33.1202 24.2345 32.965C24.0845 32.8098 24.0002 32.5994 24.0002 32.3799V31.7593C23.9977 31.3805 23.9128 31.0072 23.7518 30.6669C23.5908 30.3267 23.3578 30.0283 23.0701 29.7939Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.9082 18.395C29.886 18.5665 30.7882 19.0476 31.4904 19.772C32.1926 20.4965 32.66 21.4285 32.8283 22.4394"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}
