export default function JSIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 4.80888C0 2.15301 2.15301 0 4.80888 0H38.1908C40.8466 0 42.9997 2.15301 42.9997 4.80888V38.1911C42.9997 40.847 40.8466 43 38.1908 43H4.80889C2.15301 43 0 40.847 0 38.1911V4.80888Z"
                fill="white"
            />
            <path
                d="M39.4818 32.7442C39.1668 30.7825 37.8876 29.1348 34.0986 27.5983C32.7823 26.9935 31.3149 26.5603 30.8776 25.5628C30.7224 24.9823 30.7018 24.6553 30.8 24.3039C31.082 23.1635 32.4433 22.808 33.5222 23.135C34.2171 23.3679 34.8748 23.9034 35.2716 24.7577C37.1271 23.5561 37.1233 23.564 38.419 22.7383C37.9446 22.0029 37.6915 21.6635 37.381 21.3489C36.265 20.1023 34.7443 19.4603 32.3124 19.5095L31.0456 19.6733C29.8317 19.98 28.6748 20.6172 27.9964 21.4715C25.9609 23.7811 26.5414 27.8233 29.0183 29.4869C31.4585 31.3184 35.0432 31.735 35.501 33.4477C35.9465 35.5443 33.9601 36.2231 31.9857 35.9819C30.5307 35.6793 29.7214 34.9398 28.8466 33.595C27.2363 34.5269 27.2363 34.5269 25.5806 35.4791C25.9733 36.3375 26.3858 36.7259 27.0439 37.4696C30.1587 40.629 37.9532 40.4738 39.3512 35.6917C39.4079 35.5275 39.7844 34.4324 39.4818 32.7442ZM23.3772 19.7626H19.3552L19.3387 30.1608C19.3387 32.3722 19.4531 34.3995 19.0935 35.0209C18.5051 36.243 16.9803 36.0915 16.2854 35.8545C15.5782 35.5069 15.2185 35.0126 14.8019 34.3139C14.6875 34.1133 14.6016 33.9581 14.5728 33.9461L11.3027 35.9486C11.8465 37.0646 12.6475 38.0333 13.6735 38.6625C15.2062 39.5824 17.2661 39.8644 19.4205 39.3698C20.8226 38.9614 22.0324 38.1154 22.6658 36.8276C23.5815 35.1394 23.3851 33.0959 23.3768 30.8357C23.3974 27.1484 23.3772 23.4617 23.3772 19.7626Z"
                fill="#9C73F2"
            />
        </svg>
    )
}