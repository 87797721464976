export default function LinkedInIcon() {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.162109 21.0405C0.162109 9.42017 9.58228 0 21.2027 0C32.823 0 42.2432 9.42017 42.2432 21.0405C42.2432 32.6609 32.823 42.0811 21.2027 42.0811C9.58228 42.0811 0.162109 32.6609 0.162109 21.0405ZM10.9454 17.49V31.5608H15.4165V17.49H10.9454ZM10.6824 13.0188C10.6824 14.4654 11.7344 15.5174 13.1809 15.5174C14.6275 15.5174 15.6795 14.4654 15.6795 13.0188C15.6795 11.5723 14.6275 10.5203 13.1809 10.5203C11.8659 10.5203 10.6824 11.5723 10.6824 13.0188ZM27.2518 31.5608H31.4599V22.8816C31.4599 18.542 28.8298 17.0954 26.3313 17.0954C24.0957 17.0954 22.5177 18.542 22.1232 19.4625V17.49H17.9151V31.5608H22.3862V24.0651C22.3862 22.0926 23.7012 21.0405 25.0162 21.0405C26.3313 21.0405 27.2518 21.6981 27.2518 23.9336V31.5608Z"
        fill="#D1D1D1"
      />
    </svg>
  )
}
