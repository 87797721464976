export default function IllustratorIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.61459 3.46587e-06H35.3854C36.3856 -0.000963427 37.3762 0.200384 38.3005 0.592522C39.2248 0.98466 40.0646 1.55989 40.7718 2.28529C41.4791 3.01069 42.04 3.87203 42.4223 4.82C42.8046 5.76796 43.0009 6.78396 43 7.80983V35.1902C43.0009 36.216 42.8046 37.232 42.4223 38.18C42.04 39.128 41.4791 39.9893 40.7718 40.7147C40.0646 41.4401 39.2248 42.0153 38.3005 42.4075C37.3762 42.7996 36.3856 43.001 35.3854 43H7.61459C6.61436 43.001 5.62377 42.7996 4.6995 42.4075C3.77523 42.0153 2.93543 41.4401 2.22816 40.7147C1.52089 39.9893 0.960044 39.128 0.577709 38.18C0.195374 37.232 -0.000939342 36.216 3.37922e-06 35.1902V7.80983C-0.000939342 6.78396 0.195374 5.76796 0.577709 4.82C0.960044 3.87203 1.52089 3.01069 2.22816 2.28529C2.93543 1.55989 3.77523 0.98466 4.6995 0.592522C5.62377 0.200384 6.61436 -0.000963427 7.61459 3.46587e-06Z"
                fill="white"
            />
            <path
                d="M20.833 25.8001H14.168L12.8063 30.1184C12.7911 30.1971 12.7477 30.2672 12.6848 30.3148C12.6218 30.3624 12.5437 30.3841 12.4659 30.3757H9.09757C8.90049 30.3757 8.84674 30.2654 8.90049 30.0449L14.6697 13.0103C14.7234 12.8266 14.7772 12.6244 14.8488 12.4039C14.9209 12.0101 14.9569 11.6102 14.9563 11.2095C14.951 11.1634 14.9627 11.117 14.9893 11.0795C15.0159 11.0421 15.0553 11.0163 15.0997 11.0073H19.7401C19.8834 11.0073 19.9551 11.0625 19.973 11.1543L26.5126 30.0817C26.5663 30.2838 26.5126 30.3757 26.3334 30.3757H22.5888C22.5255 30.382 22.462 30.3651 22.4096 30.3281C22.3572 30.2912 22.3191 30.2364 22.3022 30.1736L20.833 25.8001ZM15.2072 22.0697H19.758C19.6505 21.6838 19.5072 21.2244 19.3459 20.7466C19.1847 20.2689 19.0234 19.7176 18.8622 19.1663C18.7009 18.615 18.5217 18.0454 18.3426 17.4757C18.1634 16.906 18.0022 16.3731 17.8588 15.8402C17.7155 15.3073 17.5901 14.8479 17.4647 14.4069H17.4288C17.2678 15.2078 17.0644 15.9991 16.8197 16.7774C16.5509 17.6595 16.2822 18.5783 15.9955 19.4971C15.7447 20.4342 15.4759 21.2979 15.2072 22.0697Z"
                fill="#9C73F2"
            />
            <path
                d="M30.418 14.1496C30.1239 14.1562 29.8315 14.1027 29.5577 13.9923C29.2839 13.8819 29.0343 13.7167 28.8234 13.5064C28.4129 13.0461 28.1943 12.4393 28.2142 11.8158C28.2037 11.512 28.2556 11.2094 28.3668 10.9276C28.4779 10.6458 28.6457 10.3912 28.8592 10.1803C29.2899 9.76287 29.8613 9.5324 30.4538 9.53718C31.1526 9.53718 31.6901 9.7577 32.0842 10.1803C32.4812 10.6259 32.6932 11.2125 32.6755 11.8158C32.6864 12.1263 32.6366 12.4359 32.5288 12.7262C32.4211 13.0166 32.2578 13.2819 32.0484 13.5064C31.8373 13.727 31.5825 13.8985 31.3012 14.0095C31.02 14.1204 30.7188 14.1682 30.418 14.1496ZM28.4113 30.1V15.9504C28.4113 15.7667 28.483 15.6932 28.6442 15.6932H32.1917C32.353 15.6932 32.4247 15.785 32.4247 15.9504V30.1C32.4247 30.3021 32.353 30.394 32.1917 30.394H28.6801C28.6424 30.3972 28.6045 30.3916 28.5693 30.3777C28.534 30.3638 28.5023 30.3418 28.4765 30.3136C28.4506 30.2853 28.4313 30.2514 28.4201 30.2144C28.4088 30.1775 28.4058 30.1383 28.4113 30.1Z"
                fill="#9C73F2"
            />
        </svg>
    )
}