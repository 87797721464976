export default function OfferIllustration() {
  return (
    <svg
      width="135"
      height="174"
      viewBox="0 0 135 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.917 137.728C124.869 137.728 124.839 137.727 124.829 137.726L119.152 137.727V136.87H124.849C124.981 136.875 127.673 136.952 130.153 133.872C133.848 129.282 136.883 117.919 130.354 89.8531C121.575 52.114 128.897 18.7736 132.453 6.19092C132.628 5.56841 132.659 4.91272 132.544 4.27568C132.429 3.63864 132.171 3.03769 131.79 2.52032C131.409 2.00295 130.916 1.58332 130.35 1.29461C129.783 1.0059 129.159 0.856009 128.526 0.856778H43.7389C43.0943 0.857663 42.459 1.0141 41.8845 1.31339C41.31 1.61268 40.8126 2.0464 40.4326 2.57933C38.9264 4.69994 36.8594 8.77382 35.566 16.1401L34.7422 15.9886C36.0636 8.46234 38.1978 4.26862 39.7555 2.07564C40.2133 1.43348 40.8126 0.910865 41.5047 0.550221C42.1968 0.189577 42.9623 0.00106703 43.7389 2.3313e-06H128.526C129.288 -0.000748862 130.041 0.180048 130.724 0.528107C131.406 0.876167 132.001 1.38195 132.459 2.00551C132.918 2.62906 133.229 3.3533 133.368 4.121C133.506 4.8887 133.468 5.67883 133.257 6.42897C129.72 18.9463 122.436 52.1138 131.169 89.6548C137.789 118.116 134.607 129.725 130.771 134.452C128.241 137.57 125.453 137.728 124.917 137.728Z"
        fill="#E6E6E6"
      />
      <path
        d="M109.643 153.364C109.596 153.364 109.566 153.363 109.556 153.362L103.878 153.363V152.506H109.576C109.709 152.51 112.399 152.588 114.879 149.508C118.575 144.918 121.61 133.555 115.081 105.489C106.302 67.75 113.624 34.4095 117.179 21.8269C117.355 21.2044 117.386 20.5487 117.271 19.9117C117.156 19.2746 116.898 18.6737 116.517 18.1563C116.136 17.6389 115.643 17.2193 115.076 16.9306C114.51 16.6419 113.885 16.492 113.252 16.4928H28.4656C27.8209 16.4936 27.1856 16.6501 26.6111 16.9494C26.0367 17.2487 25.5392 17.6824 25.1592 18.2153C23.653 20.3359 21.586 24.4098 20.2927 31.7761L19.4688 31.6246C20.7902 24.0983 22.9244 19.9046 24.4821 17.7116C24.9399 17.0695 25.5392 16.5469 26.2313 16.1862C26.9234 15.8256 27.6889 15.6371 28.4656 15.636H113.252C114.015 15.6352 114.767 15.816 115.45 16.1641C116.133 16.5122 116.727 17.0179 117.186 17.6415C117.645 18.265 117.956 18.9893 118.094 19.757C118.233 20.5247 118.195 21.3148 117.984 22.065C114.446 34.5823 107.162 67.7498 115.895 105.291C122.516 133.752 119.333 145.361 115.497 150.088C112.967 153.206 110.18 153.364 109.643 153.364Z"
        fill="#CCCCCC"
      />
      <g filter="url(#filter0_d_885_7244)">
        <path
          d="M94.3657 169C94.3182 169 94.2884 168.999 94.278 168.998L10.7981 168.999C9.99439 169 9.20269 168.799 8.49228 168.414C7.78187 168.03 7.17441 167.473 6.72306 166.792C6.27172 166.111 5.99024 165.328 5.90326 164.51C5.81628 163.692 5.92645 162.865 6.22413 162.101C9.25305 154.355 13.3314 135.773 6.61401 98.3399C-1.31799 54.1388 5.78326 38.1644 9.20451 33.3479C9.66231 32.7058 10.2616 32.1831 10.9537 31.8225C11.6459 31.4619 12.4114 31.2734 13.188 31.2723H97.9747C98.7374 31.2715 99.4899 31.4523 100.173 31.8004C100.855 32.1485 101.45 32.6542 101.908 33.2778C102.367 33.9014 102.678 34.6256 102.817 35.3933C102.955 36.161 102.917 36.9511 102.706 37.7013C99.1689 50.2186 91.8848 83.3861 100.618 120.927C107.238 149.388 104.056 160.997 100.22 165.724C97.6898 168.842 94.9021 169 94.3657 169ZM13.188 32.1291C12.5434 32.1299 11.9081 32.2864 11.3336 32.5857C10.7591 32.885 10.2616 33.3187 9.88158 33.8516C6.52378 38.5787 -0.436144 54.3103 7.43729 98.1851C13.8544 133.944 10.7421 152.853 7.00122 162.419C6.7491 163.05 6.65447 163.736 6.72586 164.414C6.79725 165.092 7.03243 165.741 7.41016 166.302C7.7847 166.869 8.28946 167.334 8.88016 167.654C9.47085 167.975 10.1294 168.143 10.7981 168.142H94.2985C94.4303 168.149 97.1219 168.225 99.6018 165.145C103.297 160.555 106.332 149.192 99.8033 121.125C91.0243 83.3863 98.3464 50.0459 101.902 37.4632C102.077 36.8407 102.108 36.185 101.993 35.548C101.878 34.9109 101.62 34.31 101.239 33.7926C100.858 33.2752 100.365 32.8556 99.7986 32.5669C99.232 32.2782 98.6076 32.1283 97.9747 32.1291H13.188Z"
          fill="white"
        />
        <path
          d="M13.188 32.1291C12.5434 32.1299 11.9081 32.2864 11.3336 32.5857C10.7591 32.885 10.2616 33.3187 9.88158 33.8516C6.52378 38.5787 -0.436144 54.3103 7.43729 98.1851C13.8544 133.944 10.7421 152.853 7.00122 162.419C6.7491 163.05 6.65447 163.736 6.72586 164.414C6.79725 165.092 7.03243 165.741 7.41016 166.302C7.7847 166.869 8.28946 167.334 8.88016 167.654C9.47085 167.975 10.1294 168.143 10.7981 168.142H94.2985C94.4303 168.149 97.1219 168.225 99.6018 165.145C103.297 160.555 106.332 149.192 99.8033 121.125C91.0243 83.3863 98.3464 50.0459 101.902 37.4632C102.077 36.8407 102.108 36.185 101.993 35.548C101.878 34.9109 101.62 34.31 101.239 33.7926C100.858 33.2752 100.365 32.8556 99.7986 32.5669C99.232 32.2782 98.6076 32.1283 97.9747 32.1291H13.188Z"
          fill="white"
        />
      </g>
      <path
        d="M83.5531 69.8274H17.0046C16.1166 69.8274 15.2649 69.4663 14.637 68.8236C14.009 68.1809 13.6562 67.3092 13.6562 66.4002C13.6562 65.4913 14.009 64.6196 14.637 63.9769C15.2649 63.3342 16.1166 62.9731 17.0046 62.9731H83.5531C84.4411 62.9731 85.2928 63.3342 85.9207 63.9769C86.5486 64.6196 86.9014 65.4913 86.9014 66.4002C86.9014 67.3092 86.5486 68.1809 85.9207 68.8236C85.2928 69.4663 84.4411 69.8274 83.5531 69.8274Z"
        fill="#9C73F2"
      />
      <path
        d="M83.5531 84.392H17.0046C16.1166 84.392 15.2649 84.031 14.637 83.3883C14.009 82.7456 13.6562 81.8739 13.6562 80.9649C13.6562 80.056 14.009 79.1843 14.637 78.5416C15.2649 77.8989 16.1166 77.5378 17.0046 77.5378H83.5531C84.4411 77.5378 85.2928 77.8989 85.9207 78.5416C86.5486 79.1843 86.9014 80.056 86.9014 80.9649C86.9014 81.8739 86.5486 82.7456 85.9207 83.3883C85.2928 84.031 84.4411 84.392 83.5531 84.392Z"
        fill="#CCCCCC"
      />
      <path
        d="M83.5531 98.9572H17.0046C16.1166 98.9572 15.2649 98.5962 14.637 97.9535C14.009 97.3108 13.6562 96.4391 13.6562 95.5301C13.6562 94.6212 14.009 93.7495 14.637 93.1068C15.2649 92.4641 16.1166 92.103 17.0046 92.103H83.5531C84.4411 92.103 85.2928 92.4641 85.9207 93.1068C86.5486 93.7495 86.9014 94.6212 86.9014 95.5301C86.9014 96.4391 86.5486 97.3108 85.9207 97.9535C85.2928 98.5962 84.4411 98.9572 83.5531 98.9572Z"
        fill="#CCCCCC"
      />
      <path
        d="M88.5765 113.523H22.028C21.14 113.523 20.2883 113.162 19.6604 112.519C19.0325 111.876 18.6797 111.004 18.6797 110.096C18.6797 109.187 19.0325 108.315 19.6604 107.672C20.2883 107.03 21.14 106.668 22.028 106.668H88.5765C89.4645 106.668 90.3162 107.03 90.9441 107.672C91.5721 108.315 91.9248 109.187 91.9248 110.096C91.9248 111.004 91.5721 111.876 90.9441 112.519C90.3162 113.162 89.4645 113.523 88.5765 113.523Z"
        fill="#CCCCCC"
      />
      <path
        d="M88.5765 128.088H22.028C21.14 128.088 20.2883 127.727 19.6604 127.084C19.0325 126.441 18.6797 125.57 18.6797 124.661C18.6797 123.752 19.0325 122.88 19.6604 122.237C20.2883 121.595 21.14 121.234 22.028 121.234H88.5765C89.4645 121.234 90.3162 121.595 90.9441 122.237C91.5721 122.88 91.9248 123.752 91.9248 124.661C91.9248 125.57 91.5721 126.441 90.9441 127.084C90.3162 127.727 89.4645 128.088 88.5765 128.088Z"
        fill="#CCCCCC"
      />
      <path
        d="M48.3954 55.2617H17.0046C16.1166 55.2617 15.2649 54.9006 14.637 54.2579C14.009 53.6152 13.6562 52.7435 13.6562 51.8346C13.6562 50.9257 14.009 50.054 14.637 49.4112C15.2649 48.7685 16.1166 48.4075 17.0046 48.4075H48.3954C49.2834 48.4075 50.1351 48.7685 50.763 49.4112C51.391 50.054 51.7437 50.9257 51.7437 51.8346C51.7437 52.7435 51.391 53.6152 50.763 54.2579C50.1351 54.9006 49.2834 55.2617 48.3954 55.2617Z"
        fill="#9C73F2"
      />
      <path
        d="M44.3394 151.573C45.015 148.645 48.1892 147.685 50.7843 147.792C52.4239 147.906 54.0503 148.169 55.644 148.579C57.3115 148.961 58.9628 149.413 60.6058 149.893C63.6186 150.772 66.6004 151.836 69.6629 152.523C72.2125 153.096 74.9831 153.432 77.5257 152.641C79.9697 151.882 82.0432 150.017 82.7081 147.439C83.3348 145.009 82.4744 142.353 80.1742 141.242C78.0766 140.23 75.4269 140.745 74.3872 143.034C73.215 145.616 74.3862 148.432 76.2424 150.3C77.2974 151.314 78.453 152.212 79.6903 152.98C80.9461 153.856 82.3439 154.497 83.8187 154.873C86.24 155.402 89.1242 154.622 90.3729 152.255C90.6505 151.719 90.8174 151.13 90.8633 150.525C90.9373 149.702 89.6812 149.707 89.6077 150.525C89.396 152.883 86.6985 153.987 84.6813 153.728C83.3196 153.498 82.0193 152.981 80.862 152.211C79.745 151.545 78.6865 150.78 77.6985 149.927C75.9876 148.411 74.3838 145.917 75.5329 143.553C76.4685 141.627 78.9077 141.646 80.3912 142.888C82.1878 144.392 81.8675 147.122 80.6825 148.908C79.3704 150.885 76.9923 151.714 74.7501 151.804C72.3137 151.901 69.8977 151.329 67.5692 150.652C64.676 149.811 61.8116 148.87 58.9045 148.078C57.2541 147.629 55.5909 147.223 53.9107 146.907C52.322 146.549 50.6902 146.433 49.0684 146.564C46.4483 146.874 43.7738 148.435 43.1287 151.231C42.9431 152.035 44.1535 152.379 44.3394 151.573Z"
        fill="#3F3D56"
      />
      <path
        d="M90.4764 151.877C90.3177 151.742 90.2016 151.562 90.1428 151.359C90.0841 151.156 90.0852 150.94 90.1462 150.738C90.2072 150.536 90.3252 150.357 90.4854 150.224C90.6456 150.09 90.8409 150.008 91.0465 149.988C93.3293 149.763 95.6332 150.004 97.8239 150.699L120.139 157.753C120.428 157.844 120.696 157.993 120.929 158.19C121.161 158.388 121.354 158.63 121.495 158.904C121.636 159.177 121.723 159.477 121.751 159.785C121.78 160.093 121.748 160.404 121.659 160.699C121.57 160.995 121.424 161.27 121.232 161.508C121.039 161.746 120.802 161.943 120.534 162.088C120.267 162.232 119.975 162.321 119.674 162.35C119.373 162.379 119.069 162.347 118.78 162.255L96.4653 155.202C94.2733 154.511 92.2374 153.381 90.4764 151.877Z"
        fill="#3F3D56"
      />
      <defs>
        <filter
          id="filter0_d_885_7244"
          x="0.560648"
          y="31.2723"
          width="106.328"
          height="142.606"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.43935" />
          <feGaussianBlur stdDeviation="1.21968" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_885_7244"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_885_7244"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
