export default function HTMLIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="4.3" fill="white" />
            <path
                d="M8.33203 6.71875L10.7289 33.5785L21.4847 36.55L32.2702 33.5818L34.6695 6.71875H8.33203ZM29.6841 13.078L29.5338 14.7966L29.4677 15.5847H16.8507L17.1518 18.9394H29.1678L29.0873 19.7711L28.3127 28.4259L28.2557 28.9699L21.5008 30.8267V30.8272L21.4926 30.8315L14.7422 29.0528L14.2839 23.9714H17.5936L17.8282 26.5878L21.4905 27.5657H21.5008V27.4349L25.1794 26.5063L25.5637 22.294H14.1366L13.3261 13.1605L13.2473 12.23H29.7631L29.6841 13.078Z"
                fill="#9C73F2"
            />
        </svg>
    )
}