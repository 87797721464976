export default function FigmaIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.61459 3.46587e-06H35.3854C36.3856 -0.000963427 37.3762 0.200384 38.3005 0.592522C39.2248 0.98466 40.0646 1.55989 40.7718 2.28529C41.4791 3.01069 42.04 3.87203 42.4223 4.82C42.8046 5.76796 43.0009 6.78396 43 7.80983V35.1902C43.0009 36.216 42.8046 37.232 42.4223 38.18C42.04 39.128 41.4791 39.9893 40.7718 40.7147C40.0646 41.4401 39.2248 42.0153 38.3005 42.4075C37.3762 42.7996 36.3856 43.001 35.3854 43H7.61459C6.61436 43.001 5.62377 42.7996 4.6995 42.4075C3.77523 42.0153 2.93543 41.4401 2.22816 40.7147C1.52089 39.9893 0.960044 39.128 0.577709 38.18C0.195374 37.232 -0.000939342 36.216 3.37922e-06 35.1902V7.80983C-0.000939342 6.78396 0.195374 5.76796 0.577709 4.82C0.960044 3.87203 1.52089 3.01069 2.22816 2.28529C2.93543 1.55989 3.77523 0.98466 4.6995 0.592522C5.62377 0.200384 6.61436 -0.000963427 7.61459 3.46587e-06Z"
          fill="white"
        />
        <path
          d="M17.0221 35.2818C19.4959 35.2818 21.5013 33.225 21.5013 30.6878V26.0938H17.0221C14.5484 26.0938 12.543 28.1506 12.543 30.6878C12.543 33.225 14.5484 35.2818 17.0221 35.2818Z"
          fill="#9C73F2"
          fillOpacity="0.5"
        />
        <path
          d="M12.543 21.5003C12.543 18.9631 14.5484 16.9062 17.0221 16.9062H21.5013V26.0943H17.0221C14.5484 26.0943 12.543 24.0375 12.543 21.5003Z"
          fill="#A259FF"
        />
        <path
          d="M12.543 12.3118C12.543 9.77458 14.5484 7.71777 17.0221 7.71777H21.5013V16.9058H17.0221C14.5484 16.9058 12.543 14.849 12.543 12.3118Z"
          fill="#9C73F2"
          fillOpacity="0.5"
        />
        <path
          d="M21.498 7.71777H25.9772C28.451 7.71777 30.4564 9.77458 30.4564 12.3118C30.4564 14.849 28.451 16.9058 25.9772 16.9058H21.498V7.71777Z"
          fill="#9C73F2"
          fillOpacity="0.5"
        />
        <path
          d="M30.4564 21.5003C30.4564 24.0375 28.451 26.0943 25.9772 26.0943C23.5034 26.0943 21.498 24.0375 21.498 21.5003C21.498 18.9631 23.5034 16.9062 25.9772 16.9062C28.451 16.9062 30.4564 18.9631 30.4564 21.5003Z"
          fill="#9C73F2"
        />
      </svg>
    )
}