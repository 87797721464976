export default function DockerIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="4.3" fill="white" />
            <path d="M37.9512 19.044C37.8589 18.9667 37.0251 18.3331 35.2353 18.3331C34.7646 18.3358 34.2949 18.3771 33.8309 18.4565C33.4917 16.091 31.5316 14.9471 31.4543 14.8822L30.976 14.604L30.6673 15.0524C30.278 15.6612 29.9913 16.3297 29.8185 17.0313C29.4936 18.3766 29.695 19.6439 30.3742 20.726C29.556 21.19 28.2295 21.2979 27.9514 21.3109H6.03776C5.9012 21.3108 5.76598 21.3377 5.63987 21.3901C5.51376 21.4425 5.39927 21.5193 5.30298 21.6161C5.20669 21.713 5.13051 21.8279 5.07883 21.9543C5.02714 22.0807 5.00098 22.2161 5.00184 22.3526C4.9724 24.2765 5.29734 26.1894 5.96042 27.9956C6.71689 29.9745 7.84315 31.4465 9.2911 32.3395C10.9301 33.3442 13.5999 33.9161 16.6089 33.9161C17.9702 33.9162 19.3287 33.792 20.6674 33.545C22.5338 33.2066 24.329 32.5532 25.9764 31.6129C27.3325 30.825 28.5521 29.8227 29.5878 28.6449C31.3314 26.6815 32.3654 24.4856 33.1219 22.5359H33.4306C35.3289 22.5359 36.502 21.7781 37.1499 21.1289C37.58 20.7213 37.9169 20.2256 38.1377 19.6757L38.2768 19.2741L37.9512 19.044Z" fill="#9C73F2" />
            <path d="M8.07441 20.6824H11.0061C11.075 20.6824 11.1411 20.655 11.1899 20.6063C11.2386 20.5575 11.266 20.4914 11.266 20.4224V17.7917C11.266 17.7227 11.2386 17.6566 11.1899 17.6079C11.1411 17.5591 11.075 17.5317 11.0061 17.5317H8.07441C8.00546 17.5317 7.93934 17.5591 7.89059 17.6079C7.84184 17.6566 7.81445 17.7227 7.81445 17.7917V20.4198C7.81703 20.488 7.84524 20.5526 7.89345 20.6008C7.94165 20.649 8.00629 20.6772 8.07441 20.6798" fill="#9C73F2" />
            <path d="M12.1174 20.6824H15.0497C15.1186 20.6824 15.1847 20.655 15.2335 20.6063C15.2822 20.5575 15.3096 20.4914 15.3096 20.4224V17.7917C15.3096 17.7227 15.2822 17.6566 15.2335 17.6079C15.1847 17.5591 15.1186 17.5317 15.0497 17.5317H12.1174C12.0484 17.5317 11.9823 17.5591 11.9336 17.6079C11.8848 17.6566 11.8574 17.7227 11.8574 17.7917V20.4198C11.86 20.488 11.8882 20.5526 11.9364 20.6008C11.9846 20.649 12.0493 20.6772 12.1174 20.6798" fill="#9C73F2" />
            <path d="M16.2386 20.6824H19.1696C19.2386 20.6824 19.3047 20.655 19.3534 20.6063C19.4022 20.5575 19.4296 20.4914 19.4296 20.4224V17.7917C19.4296 17.7227 19.4022 17.6566 19.3534 17.6079C19.3047 17.5591 19.2386 17.5317 19.1696 17.5317H16.2386C16.1697 17.5317 16.1036 17.5591 16.0548 17.6079C16.0061 17.6566 15.9787 17.7227 15.9787 17.7917V20.4198C15.9775 20.4543 15.9834 20.4886 15.9961 20.5207C16.0087 20.5528 16.0278 20.5819 16.0522 20.6063C16.0766 20.6307 16.1057 20.6498 16.1378 20.6624C16.1698 20.6751 16.2042 20.681 16.2386 20.6798" fill="#9C73F2" />
            <path d="M20.299 20.6824H23.2313C23.3003 20.6824 23.3664 20.655 23.4151 20.6063C23.4639 20.5575 23.4913 20.4914 23.4913 20.4224V17.7917C23.4913 17.7227 23.4639 17.6566 23.4151 17.6079C23.3664 17.5591 23.3003 17.5317 23.2313 17.5317H20.299C20.2301 17.5317 20.164 17.5591 20.1152 17.6079C20.0665 17.6566 20.0391 17.7227 20.0391 17.7917V20.4198C20.0391 20.4888 20.0665 20.5549 20.1152 20.6037C20.164 20.6524 20.2301 20.6798 20.299 20.6798" fill="#9C73F2" />
            <path d="M12.1174 16.9261H15.0497C15.1178 16.9235 15.1824 16.8953 15.2306 16.8471C15.2788 16.7989 15.3071 16.7342 15.3096 16.6661V14.0353C15.3096 13.9664 15.2822 13.9003 15.2335 13.8515C15.1847 13.8028 15.1186 13.7754 15.0497 13.7754H12.1174C12.0484 13.7754 11.9823 13.8028 11.9336 13.8515C11.8848 13.9003 11.8574 13.9664 11.8574 14.0353V16.6635C11.8625 16.7308 11.8915 16.794 11.9392 16.8416C11.9869 16.8893 12.0501 16.9184 12.1174 16.9235" fill="#9C73F2" />
            <path d="M16.2404 16.9261H19.1714C19.2395 16.9235 19.3042 16.8953 19.3524 16.8471C19.4006 16.7989 19.4288 16.7342 19.4314 16.6661V14.0353C19.4314 13.9664 19.404 13.9003 19.3552 13.8515C19.3065 13.8028 19.2404 13.7754 19.1714 13.7754H16.2404C16.1715 13.7754 16.1054 13.8028 16.0566 13.8515C16.0079 13.9003 15.9805 13.9664 15.9805 14.0353V16.6635C15.9805 16.7324 16.0079 16.7986 16.0566 16.8473C16.1054 16.8961 16.1715 16.9235 16.2404 16.9235" fill="#9C73F2" />
            <path d="M20.299 16.9261H23.2313C23.2994 16.9235 23.3641 16.8953 23.4123 16.8471C23.4605 16.7989 23.4887 16.7342 23.4913 16.6661V14.0353C23.4887 13.9672 23.4605 13.9026 23.4123 13.8544C23.3641 13.8062 23.2994 13.778 23.2313 13.7754H20.299C20.2301 13.7754 20.164 13.8028 20.1152 13.8515C20.0665 13.9003 20.0391 13.9664 20.0391 14.0353V16.6635C20.0416 16.7316 20.0698 16.7963 20.1181 16.8445C20.1663 16.8927 20.2309 16.9209 20.299 16.9235" fill="#9C73F2" />
            <path d="M20.299 13.1539H23.2313C23.3003 13.1539 23.3664 13.1265 23.4151 13.0778C23.4639 13.029 23.4913 12.9629 23.4913 12.894V10.26C23.4887 10.1918 23.4605 10.1272 23.4123 10.079C23.3641 10.0308 23.2994 10.0026 23.2313 10H20.299C20.2301 10 20.164 10.0274 20.1152 10.0761C20.0665 10.1249 20.0391 10.191 20.0391 10.26V12.8881C20.0418 12.9562 20.07 13.0207 20.1182 13.0689C20.1664 13.1171 20.2309 13.1453 20.299 13.1481" fill="#9C73F2" />
            <path d="M24.385 20.6824H27.3173C27.3862 20.6824 27.4523 20.655 27.5011 20.6063C27.5498 20.5575 27.5772 20.4914 27.5772 20.4224V17.7917C27.5772 17.7227 27.5498 17.6566 27.5011 17.6079C27.4523 17.5591 27.3862 17.5317 27.3173 17.5317H24.385C24.316 17.5317 24.2499 17.5591 24.2011 17.6079C24.1524 17.6566 24.125 17.7227 24.125 17.7917V20.4198C24.1276 20.488 24.1558 20.5526 24.204 20.6008C24.2522 20.649 24.3168 20.6772 24.385 20.6798" fill="#9C73F2" />
        </svg>
    )
}