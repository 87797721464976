export default function PHPIcon() {
    return (
        <svg width="86" height="43" viewBox="0 0 86 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="43" cy="21.5" rx="43" ry="21.5" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.2648 27.4223C29.5216 27.0201 30.6629 26.6296 31.6907 25.6836C32.5248 24.932 33.232 24.0546 33.7857 23.0842C34.32 22.1438 34.6986 21.0882 34.9234 19.9528C35.4676 17.1974 35.0574 15.0148 33.6919 13.4772C32.3265 11.9396 30.1547 11.0969 27.1771 11.0969H17.28L12.6875 34.6776H17.8341L19.0557 27.7421H23.4636C23.7556 27.7421 24.0399 27.744 24.3164 27.7458C25.8811 27.7561 27.1966 27.7648 28.2648 27.4223ZM48.7441 17.7411L46.7132 27.7421H51.9L54.0366 17.1717C54.4708 14.9718 54.1451 13.6263 53.0589 12.6442C51.9726 11.6622 49.9988 11.0969 47.1361 11.0969H42.5873L43.8082 4.85498H38.7018L34.1093 27.7421H39.2157L41.8457 14.5647H45.9272C47.2334 14.5647 48.0878 14.8497 48.4896 15.2755C48.8907 15.7007 48.9753 16.5704 48.7441 17.7411ZM73.9555 23.1022C74.4891 22.1625 74.8683 21.1249 75.0925 19.9896C75.6367 17.2348 75.2257 15.0148 73.8617 13.4772C72.497 11.9396 70.3251 11.0969 67.3475 11.0969H57.4498L52.858 34.6776H58.0046L59.2255 27.7421H63.6333C63.9254 27.7421 64.2096 27.744 64.4862 27.7458C66.0512 27.7561 67.3677 27.7648 68.4352 27.4223C69.6914 27.0201 70.8334 26.6296 71.8611 25.6836C72.7233 24.9041 73.4211 24.0434 73.9555 23.1022ZM61.8717 14.5647H65.397C67.3525 14.5647 68.6559 15.0481 69.3079 15.7583C69.9593 16.4684 70.1144 17.816 69.7732 19.5436C69.4172 21.342 68.7327 22.5349 67.7162 23.3034C66.7011 24.0732 65.1546 24.2743 63.0807 24.2743H59.9494L61.8717 14.5647ZM21.7013 14.5647H25.2273C27.1827 14.5647 28.4854 15.0481 29.1368 15.7583C29.7881 16.4684 29.9432 17.816 29.602 19.5436C29.2467 21.342 28.5616 22.5349 27.5458 23.3034C26.53 24.0732 24.9841 24.2743 22.9095 24.2743H19.779L21.7013 14.5647Z"
                fill="#9C73F2"
            />
        </svg>
    )
}