export default function DesignIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="170"
      viewBox="0 0 132 170"
      fill="none"
    >
      <path
        d="M130.156 0H1.84431C1.35534 0.00101765 0.886542 0.36581 0.540784 1.01433C0.195026 1.66286 0.000542557 2.54215 0 3.4593V166.541C0.000543253 167.458 0.195034 168.337 0.540792 168.986C0.88655 169.634 1.35534 169.999 1.84431 170H130.156C130.645 169.999 131.113 169.634 131.459 168.986C131.805 168.337 131.999 167.458 132 166.541V3.4593C131.999 2.54215 131.805 1.66286 131.459 1.01433C131.113 0.36581 130.645 0.00101765 130.156 0ZM131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M131.063 14.0829H0.935547V15.0888H131.063V14.0829Z"
        fill="#3F3D56"
      />
      <path
        d="M8.5024 10.6726C10.0678 10.6726 11.3368 9.3074 11.3368 7.6233C11.3368 5.9392 10.0678 4.57397 8.5024 4.57397C6.93699 4.57397 5.66797 5.9392 5.66797 7.6233C5.66797 9.3074 6.93699 10.6726 8.5024 10.6726Z"
        fill="#3F3D56"
      />
      <path
        d="M16.2973 10.6726C17.8627 10.6726 19.1318 9.3074 19.1318 7.6233C19.1318 5.9392 17.8627 4.57397 16.2973 4.57397C14.7319 4.57397 13.4629 5.9392 13.4629 7.6233C13.4629 9.3074 14.7319 10.6726 16.2973 10.6726Z"
        fill="#3F3D56"
      />
      <path
        d="M24.0922 10.6726C25.6577 10.6726 26.9267 9.3074 26.9267 7.6233C26.9267 5.9392 25.6577 4.57397 24.0922 4.57397C22.5268 4.57397 21.2578 5.9392 21.2578 7.6233C21.2578 9.3074 22.5268 10.6726 24.0922 10.6726Z"
        fill="#3F3D56"
      />
      <path
        d="M15.8984 24.2448L15.8982 24.2448C15.5492 24.2452 15.2177 24.3947 14.9754 24.6553C14.7335 24.9155 14.5996 25.2656 14.5992 25.6282V25.6284L14.5992 52.3661L14.5992 52.3663C14.5996 52.7288 14.7335 53.0789 14.9754 53.3392C15.2177 53.5998 15.5492 53.7493 15.8982 53.7497H15.8984H116.091H116.091C116.44 53.7493 116.771 53.5998 117.014 53.3392C117.256 53.079 117.39 52.7288 117.39 52.3663V52.3661V25.6284V25.6282C117.39 25.2656 117.256 24.9155 117.014 24.6553C116.771 24.3947 116.44 24.2452 116.091 24.2448H116.091L15.8984 24.2448ZM116.09 54.1763H15.8986C15.4605 54.1757 15.0375 53.9883 14.7232 53.6503C14.4085 53.3117 14.2294 52.8501 14.2289 52.3659L14.2289 25.6285C14.2294 25.1444 14.4086 24.6827 14.7232 24.3442C15.0374 24.0062 15.4604 23.8188 15.8985 23.8182H116.091C116.529 23.8188 116.952 24.0062 117.266 24.3442C117.581 24.6827 117.76 25.1444 117.76 25.6286V52.3659C117.76 52.8501 117.581 53.3118 117.266 53.6503C116.952 53.9883 116.529 54.1757 116.09 54.1763Z"
        fill="#CCCCCC"
        stroke="#1A1A1A"
        strokeWidth="0.37182"
      />
      <path
        d="M116.091 160.154H15.8984C15.4065 160.154 14.9349 159.943 14.5871 159.569C14.2392 159.195 14.0436 158.687 14.043 158.158V108.665C14.0435 108.136 14.2392 107.629 14.587 107.255C14.9349 106.88 15.4065 106.67 15.8984 106.669H116.091C116.583 106.67 117.054 106.88 117.402 107.255C117.75 107.629 117.946 108.136 117.946 108.665V158.158C117.946 158.687 117.75 159.195 117.402 159.569C117.054 159.943 116.583 160.154 116.091 160.154ZM15.8984 107.468C15.6032 107.468 15.3202 107.594 15.1115 107.819C14.9028 108.043 14.7854 108.348 14.7851 108.665V158.158C14.7854 158.476 14.9028 158.78 15.1115 159.005C15.3202 159.229 15.6032 159.356 15.8984 159.356H116.091C116.386 159.356 116.669 159.229 116.878 159.005C117.086 158.78 117.204 158.476 117.204 158.158V108.665C117.204 108.348 117.086 108.043 116.878 107.819C116.669 107.594 116.386 107.468 116.091 107.468L15.8984 107.468Z"
        fill="#1A1A1A"
      />
      <path
        d="M115.35 95.8807H70.8203C70.3283 95.8801 69.8567 95.6696 69.5089 95.2954C69.1611 94.9212 68.9654 94.4139 68.9648 93.8846V67.147C68.9654 66.6178 69.1611 66.1104 69.5089 65.7362C69.8567 65.362 70.3283 65.1515 70.8203 65.1509H115.35C115.842 65.1515 116.314 65.362 116.662 65.7362C117.009 66.1104 117.205 66.6178 117.206 67.147V93.8847C117.205 94.4139 117.009 94.9212 116.662 95.2954C116.314 95.6696 115.842 95.8801 115.35 95.8807ZM70.8203 65.9493C70.5251 65.9497 70.2421 66.0759 70.0334 66.3005C69.8247 66.525 69.7073 66.8294 69.707 67.147V93.8846C69.7073 94.2022 69.8247 94.5066 70.0334 94.7311C70.2421 94.9557 70.5251 95.082 70.8203 95.0823H115.35C115.645 95.082 115.928 94.9557 116.137 94.7311C116.346 94.5066 116.463 94.2022 116.464 93.8846V67.1469C116.463 66.8294 116.346 66.525 116.137 66.3004C115.928 66.0759 115.645 65.9496 115.35 65.9493L70.8203 65.9493Z"
        fill="#9C73F2"
      />
      <path
        d="M70.8203 65.9493C70.5251 65.9497 70.2421 66.0759 70.0334 66.3005C69.8247 66.525 69.7073 66.8294 69.707 67.147V93.8846C69.7073 94.2022 69.8247 94.5066 70.0334 94.7311C70.2421 94.9557 70.5251 95.082 70.8203 95.0823H115.35C115.645 95.082 115.928 94.9557 116.137 94.7311C116.346 94.5066 116.463 94.2022 116.464 93.8846V67.1469C116.463 66.8294 116.346 66.525 116.137 66.3004C115.928 66.0759 115.645 65.9496 115.35 65.9493L70.8203 65.9493Z"
        fill="#9C73F2"
      />
      <path
        d="M30.724 132.333C34.8981 132.333 38.2819 128.693 38.2819 124.202C38.2819 119.711 34.8981 116.071 30.724 116.071C26.5498 116.071 23.166 119.711 23.166 124.202C23.166 128.693 26.5498 132.333 30.724 132.333Z"
        fill="#1A1A1A"
      />
      <path
        d="M48.1776 118.781C47.8435 118.781 47.5232 118.924 47.2869 119.179C47.0507 119.433 46.918 119.778 46.918 120.137C46.918 120.497 47.0507 120.841 47.2869 121.096C47.5232 121.35 47.8435 121.493 48.1776 121.493H107.562C107.895 121.492 108.215 121.349 108.45 121.094C108.686 120.84 108.818 120.496 108.818 120.137C108.818 119.778 108.686 119.434 108.45 119.18C108.215 118.926 107.895 118.782 107.562 118.781H48.1776Z"
        fill="#1A1A1A"
      />
      <path
        d="M48.177 126.912C47.8432 126.912 47.5228 127.054 47.2864 127.308C47.0499 127.561 46.9167 127.906 46.916 128.265C46.9153 128.624 47.0472 128.969 47.2826 129.224C47.5181 129.479 47.8379 129.622 48.1717 129.623H73.7301C74.0642 129.623 74.3846 129.481 74.6208 129.226C74.8571 128.972 74.9898 128.627 74.9898 128.268C74.9898 127.908 74.8571 127.564 74.6208 127.309C74.3846 127.055 74.0642 126.912 73.7301 126.912H48.177Z"
        fill="#1A1A1A"
      />
      <path
        d="M24.9274 139.911C23.9692 139.911 23.1895 140.52 23.1895 141.267C23.1895 142.014 23.9692 142.623 24.9274 142.623H106.859C107.817 142.623 108.597 142.014 108.597 141.267C108.597 140.52 107.817 139.911 106.859 139.911H24.9274Z"
        fill="#1A1A1A"
      />
      <path
        d="M24.9274 148.042C23.9692 148.042 23.1895 148.651 23.1895 149.398C23.1895 150.145 23.9692 150.753 24.9274 150.753H106.859C107.817 150.753 108.597 150.145 108.597 149.398C108.597 148.651 107.817 148.042 106.859 148.042H24.9274Z"
        fill="#1A1A1A"
      />
      <path
        d="M31.4662 46.1026C35.6403 46.1026 39.0241 42.4623 39.0241 37.9717C39.0241 33.4811 35.6403 29.8407 31.4662 29.8407C27.292 29.8407 23.9082 33.4811 23.9082 37.9717C23.9082 42.4623 27.292 46.1026 31.4662 46.1026Z"
        fill="#1A1A1A"
      />
      <path
        d="M76.0481 76.2333C77.8092 76.2333 79.2369 74.6974 79.2369 72.8028C79.2369 70.9082 77.8092 69.3723 76.0481 69.3723C74.287 69.3723 72.8594 70.9082 72.8594 72.8028C72.8594 74.6974 74.287 76.2333 76.0481 76.2333Z"
        fill="#1A1A1A"
      />
      <path
        d="M48.9208 32.5508C48.5872 32.5516 48.2676 32.6948 48.032 32.9489C47.7964 33.2031 47.6641 33.5473 47.6641 33.9063C47.6641 34.2653 47.7964 34.6096 48.032 34.8637C48.2676 35.1178 48.5872 35.261 48.9208 35.2618H108.305C108.47 35.2624 108.634 35.2278 108.787 35.1602C108.941 35.0925 109.08 34.993 109.197 34.8673C109.314 34.7417 109.408 34.5924 109.471 34.428C109.535 34.2636 109.568 34.0873 109.568 33.9092C109.569 33.731 109.536 33.5546 109.473 33.3899C109.41 33.2252 109.318 33.0755 109.201 32.9493C109.084 32.8232 108.945 32.723 108.792 32.6547C108.639 32.5863 108.476 32.551 108.31 32.5508C108.308 32.5508 108.307 32.5508 108.305 32.5508H48.9208Z"
        fill="#1A1A1A"
      />
      <path
        d="M48.9216 40.6816C48.5879 40.6822 48.2681 40.8253 48.0323 41.0795C47.7965 41.3336 47.6641 41.6781 47.6641 42.0372C47.6641 42.3963 47.7965 42.7407 48.0323 42.9949C48.2681 43.249 48.5879 43.3921 48.9216 43.3927H74.4747C74.8088 43.3935 75.1295 43.2514 75.3662 42.9977C75.603 42.744 75.7364 42.3995 75.7371 42.04C75.7378 41.6805 75.6057 41.3354 75.37 41.0807C75.1343 40.8259 74.8141 40.6824 74.4801 40.6816C74.4783 40.6816 74.4765 40.6816 74.4747 40.6816H48.9216Z"
        fill="#1A1A1A"
      />
      <path
        d="M60.4284 95.8807H15.8984C15.4065 95.8801 14.9349 95.6696 14.587 95.2954C14.2392 94.9212 14.0435 94.4138 14.043 93.8846V67.147C14.0435 66.6178 14.2392 66.1104 14.587 65.7362C14.9349 65.362 15.4065 65.1515 15.8984 65.1509H60.4284C60.9203 65.1515 61.3919 65.362 61.7397 65.7362C62.0876 66.1104 62.2832 66.6178 62.2838 67.147V93.8847C62.2832 94.4139 62.0875 94.9212 61.7397 95.2954C61.3919 95.6696 60.9203 95.8801 60.4284 95.8807ZM15.7003 65.7362C15.4051 65.7366 15.1222 65.8629 14.9135 66.0874C14.7048 66.3119 14.5874 66.6164 14.587 66.9339V93.6716C14.5874 93.9891 14.7048 94.2935 14.9135 94.5181C15.1222 94.7426 15.4051 94.8689 15.7003 94.8692H60.2303C60.5255 94.8689 60.8085 94.7426 61.0172 94.5181C61.2259 94.2935 61.3433 93.9891 61.3436 93.6716V66.9339C61.3433 66.6163 61.2259 66.3119 61.0172 66.0874C60.8085 65.8628 60.5255 65.7365 60.2303 65.7362L15.7003 65.7362Z"
        fill="#9C73F2"
      />
      <path
        d="M15.7003 65.7362C15.4051 65.7366 15.1222 65.8629 14.9135 66.0874C14.7048 66.3119 14.5874 66.6164 14.587 66.9339V93.6716C14.5874 93.9891 14.7048 94.2935 14.9135 94.5181C15.1222 94.7426 15.4051 94.8689 15.7003 94.8692H60.2303C60.5255 94.8689 60.8085 94.7426 61.0172 94.5181C61.2259 94.2935 61.3433 93.9891 61.3436 93.6716V66.9339C61.3433 66.6163 61.2259 66.3119 61.0172 66.0874C60.8085 65.8628 60.5255 65.7365 60.2303 65.7362L15.7003 65.7362Z"
        fill="#9C73F2"
      />
      <path
        d="M60.3863 95.8857H18.6133L28.4439 77.5674C28.62 77.239 28.8735 76.9664 29.1786 76.7768C29.4838 76.5873 29.83 76.4875 30.1824 76.4875C30.5348 76.4875 30.881 76.5873 31.1862 76.7768C31.4913 76.9664 31.7448 77.239 31.9209 77.5674L36.1204 85.3926L43.261 72.0871C43.4816 71.6761 43.7989 71.3347 44.181 71.0974C44.5631 70.8601 44.9965 70.7351 45.4377 70.7351C45.8789 70.7351 46.3123 70.8601 46.6944 71.0974C47.0765 71.3347 47.3938 71.6761 47.6144 72.0871L60.3863 95.8857ZM19.8987 95.0872H59.1009L46.9715 72.4863C46.8161 72.1966 46.5926 71.9561 46.3233 71.7888C46.0541 71.6216 45.7486 71.5335 45.4377 71.5335C45.1268 71.5335 44.8214 71.6216 44.5522 71.7888C44.2829 71.9561 44.0594 72.1966 43.904 72.4863L36.1205 86.9895L31.2781 77.9666C31.1671 77.7596 31.0074 77.5878 30.8151 77.4683C30.6227 77.3488 30.4045 77.2859 30.1824 77.2859C29.9603 77.2859 29.7421 77.3488 29.5498 77.4683C29.3575 77.5878 29.1978 77.7596 29.0868 77.9666L19.8987 95.0872Z"
        fill="#1A1A1A"
      />
      <path
        d="M19.8987 95.0872H59.1009L46.9715 72.4863C46.8161 72.1966 46.5926 71.9561 46.3233 71.7888C46.0541 71.6216 45.7486 71.5335 45.4377 71.5335C45.1268 71.5335 44.8214 71.6216 44.5522 71.7888C44.2829 71.9561 44.0594 72.1966 43.904 72.4863L36.1205 86.9895L31.2781 77.9666C31.1671 77.7596 31.0074 77.5878 30.8151 77.4683C30.6227 77.3488 30.4045 77.2859 30.1824 77.2859C29.9603 77.2859 29.7421 77.3488 29.5498 77.4683C29.3575 77.5878 29.1978 77.7596 29.0868 77.9666L19.8987 95.0872Z"
        fill="#1A1A1A"
      />
      <path
        d="M113.826 95.8857H72.0527L81.8833 77.5674C82.0595 77.239 82.3129 76.9664 82.6181 76.7768C82.9233 76.5873 83.2695 76.4875 83.6219 76.4875C83.9743 76.4875 84.3204 76.5873 84.6256 76.7768C84.9308 76.9664 85.1842 77.239 85.3604 77.5674L89.5599 85.3926L96.7005 72.0871C96.9211 71.6761 97.2384 71.3347 97.6205 71.0974C98.0025 70.8601 98.436 70.7351 98.8772 70.7351C99.3184 70.7351 99.7518 70.8601 100.134 71.0974C100.516 71.3347 100.833 71.6761 101.054 72.0871L113.826 95.8857ZM73.3381 95.0872H112.54L100.411 72.4863C100.256 72.1966 100.032 71.9561 99.7627 71.7888C99.4935 71.6216 99.1881 71.5335 98.8772 71.5335C98.5663 71.5335 98.2609 71.6216 97.9916 71.7888C97.7224 71.9561 97.4988 72.1966 97.3434 72.4863L89.5599 86.9895L84.7175 77.9666C84.6065 77.7596 84.4468 77.5878 84.2545 77.4683C84.0622 77.3488 83.844 77.2859 83.6219 77.2859C83.3998 77.2859 83.1816 77.3488 82.9892 77.4683C82.7969 77.5878 82.6372 77.7596 82.5262 77.9666L73.3381 95.0872Z"
        fill="black"
      />
      <path
        d="M73.3381 95.0872H112.54L100.411 72.4863C100.256 72.1966 100.032 71.9561 99.7627 71.7888C99.4935 71.6216 99.1881 71.5335 98.8772 71.5335C98.5663 71.5335 98.2609 71.6216 97.9916 71.7888C97.7224 71.9561 97.4988 72.1966 97.3434 72.4863L89.5599 86.9895L84.7175 77.9666C84.6065 77.7596 84.4468 77.5878 84.2545 77.4683C84.0622 77.3488 83.844 77.2859 83.6219 77.2859C83.3998 77.2859 83.1816 77.3488 82.9892 77.4683C82.7969 77.5878 82.6372 77.7596 82.5262 77.9666L73.3381 95.0872Z"
        fill="black"
      />
      <path
        d="M22.1926 76.2333C23.9537 76.2333 25.3814 74.6974 25.3814 72.8028C25.3814 70.9082 23.9537 69.3723 22.1926 69.3723C20.4316 69.3723 19.0039 70.9082 19.0039 72.8028C19.0039 74.6974 20.4316 76.2333 22.1926 76.2333Z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
