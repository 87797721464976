export default function PrototypeIllustration() {
  return (
    <svg
      width="132"
      height="170"
      viewBox="0 0 132 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M130.156 0H1.84431C1.35534 0.00101765 0.886542 0.36581 0.540784 1.01433C0.195026 1.66286 0.000542557 2.54215 0 3.4593V166.541C0.000543253 167.458 0.195034 168.337 0.540792 168.986C0.88655 169.634 1.35534 169.999 1.84431 170H130.156C130.645 169.999 131.113 169.634 131.459 168.986C131.805 168.337 131.999 167.458 132 166.541V3.4593C131.999 2.54215 131.805 1.66286 131.459 1.01433C131.113 0.36581 130.645 0.00101765 130.156 0ZM131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M130.985 14.0828H1.01562V15.0887H130.985V14.0828Z"
        fill="#3F3D56"
      />
      <path
        d="M9.22466 10.6726C10.9225 10.6726 12.2989 9.3074 12.2989 7.6233C12.2989 5.9392 10.9225 4.57397 9.22466 4.57397C7.52679 4.57397 6.15039 5.9392 6.15039 7.6233C6.15039 9.3074 7.52679 10.6726 9.22466 10.6726Z"
        fill="#3F3D56"
      />
      <path
        d="M17.6778 10.6726C19.3757 10.6726 20.7521 9.30734 20.7521 7.62324C20.7521 5.93914 19.3757 4.57391 17.6778 4.57391C15.9799 4.57391 14.6035 5.93914 14.6035 7.62324C14.6035 9.30734 15.9799 10.6726 17.6778 10.6726Z"
        fill="#3F3D56"
      />
      <path
        d="M26.1309 10.6726C27.8288 10.6726 29.2052 9.30734 29.2052 7.62324C29.2052 5.93914 27.8288 4.57391 26.1309 4.57391C24.433 4.57391 23.0566 5.93914 23.0566 7.62324C23.0566 9.30734 24.433 10.6726 26.1309 10.6726Z"
        fill="#3F3D56"
      />
      <path
        d="M120.837 54.3619H12.1667C11.6332 54.3613 11.1217 54.1508 10.7444 53.7766C10.3671 53.4024 10.1549 52.895 10.1543 52.3658V25.6282C10.1549 25.099 10.3671 24.5916 10.7444 24.2174C11.1217 23.8432 11.6332 23.6327 12.1667 23.6321H120.837C121.37 23.6327 121.882 23.8432 122.259 24.2174C122.636 24.5916 122.849 25.099 122.849 25.6282V52.3659C122.849 52.8951 122.636 53.4024 122.259 53.7766C121.882 54.1508 121.37 54.3613 120.837 54.3619ZM12.1667 24.4305C11.8466 24.4308 11.5397 24.5571 11.3133 24.7817C11.0869 25.0062 10.9596 25.3106 10.9592 25.6282V52.3658C10.9596 52.6834 11.0869 52.9878 11.3133 53.2123C11.5397 53.4369 11.8466 53.5632 12.1667 53.5635H120.837C121.157 53.5632 121.464 53.4369 121.69 53.2123C121.917 52.9878 122.044 52.6834 122.044 52.3658V25.6282C122.044 25.3106 121.917 25.0062 121.69 24.7817C121.464 24.5571 121.157 24.4308 120.837 24.4305L12.1667 24.4305Z"
        fill="#CCCCCC"
      />
      <path
        d="M120.837 160.154H12.1667C11.6332 160.153 11.1217 159.943 10.7444 159.569C10.3672 159.195 10.1549 158.687 10.1543 158.158V108.665C10.1549 108.136 10.3671 107.628 10.7444 107.254C11.1217 106.88 11.6332 106.67 12.1667 106.669H120.837C121.37 106.67 121.882 106.88 122.259 107.254C122.636 107.628 122.849 108.136 122.849 108.665V158.158C122.849 158.687 122.636 159.195 122.259 159.569C121.882 159.943 121.37 160.153 120.837 160.154ZM12.1667 107.467C11.8466 107.468 11.5397 107.594 11.3133 107.819C11.0869 108.043 10.9596 108.348 10.9592 108.665V158.158C10.9596 158.476 11.0869 158.78 11.3133 159.004C11.5397 159.229 11.8466 159.355 12.1667 159.356H120.837C121.157 159.355 121.464 159.229 121.69 159.005C121.917 158.78 122.044 158.476 122.044 158.158V108.665C122.044 108.347 121.917 108.043 121.69 107.819C121.464 107.594 121.157 107.468 120.837 107.467L12.1667 107.467Z"
        fill="#CCCCCC"
      />
      <path
        d="M120.031 95.8805H71.7331C71.1996 95.8799 70.6881 95.6694 70.3108 95.2952C69.9335 94.921 69.7213 94.4137 69.7207 93.8845V67.1468C69.7213 66.6176 69.9335 66.1102 70.3108 65.736C70.6881 65.3618 71.1996 65.1513 71.7331 65.1507H120.031C120.565 65.1513 121.076 65.3618 121.453 65.736C121.831 66.1102 122.043 66.6176 122.043 67.1468V93.8845C122.043 94.4137 121.831 94.921 121.453 95.2952C121.076 95.6694 120.565 95.8799 120.031 95.8805ZM71.7331 65.9491C71.413 65.9495 71.1061 66.0758 70.8797 66.3003C70.6533 66.5248 70.526 66.8293 70.5257 67.1468V93.8845C70.526 94.202 70.6533 94.5064 70.8797 94.731C71.1061 94.9555 71.413 95.0818 71.7331 95.0821H120.031C120.351 95.0818 120.658 94.9555 120.884 94.731C121.111 94.5064 121.238 94.202 121.239 93.8845V67.1468C121.238 66.8292 121.111 66.5248 120.884 66.3003C120.658 66.0757 120.351 65.9494 120.031 65.9491L71.7331 65.9491Z"
        fill="#CCCCCC"
      />
      <path
        d="M28.2463 132.333C32.7737 132.333 36.4438 128.692 36.4438 124.202C36.4438 119.711 32.7737 116.071 28.2463 116.071C23.719 116.071 20.0488 119.711 20.0488 124.202C20.0488 128.692 23.719 132.333 28.2463 132.333Z"
        fill="#CCCCCC"
      />
      <path
        d="M47.1787 118.781C46.8164 118.781 46.4689 118.924 46.2127 119.178C45.9564 119.432 45.8125 119.777 45.8125 120.136C45.8125 120.496 45.9564 120.84 46.2127 121.095C46.4689 121.349 46.8164 121.491 47.1787 121.491H111.588C111.949 121.491 112.296 121.347 112.552 121.093C112.807 120.839 112.951 120.495 112.951 120.136C112.951 119.777 112.807 119.433 112.552 119.179C112.296 118.925 111.949 118.782 111.588 118.781H47.1787Z"
        fill="#CCCCCC"
      />
      <path
        d="M47.1783 126.912C46.8162 126.912 46.4687 127.054 46.2122 127.307C45.9558 127.561 45.8113 127.905 45.8105 128.264C45.8098 128.624 45.9528 128.968 46.2082 129.223C46.4636 129.478 46.8104 129.621 47.1725 129.622H74.8936C75.2559 129.622 75.6034 129.48 75.8596 129.225C76.1159 128.971 76.2598 128.627 76.2598 128.267C76.2598 127.908 76.1159 127.563 75.8596 127.309C75.6034 127.055 75.2559 126.912 74.8936 126.912H47.1783Z"
        fill="#CCCCCC"
      />
      <path
        d="M21.9592 139.911C20.9199 139.911 20.0742 140.519 20.0742 141.266C20.0742 142.013 20.9199 142.621 21.9592 142.621H110.824C111.863 142.621 112.709 142.013 112.709 141.266C112.709 140.519 111.863 139.911 110.824 139.911H21.9592Z"
        fill="#CCCCCC"
      />
      <path
        d="M21.9592 148.042C20.9199 148.042 20.0742 148.65 20.0742 149.397C20.0742 150.144 20.9199 150.752 21.9592 150.752H110.824C111.863 150.752 112.709 150.144 112.709 149.397C112.709 148.65 111.863 148.042 110.824 148.042H21.9592Z"
        fill="#CCCCCC"
      />
      <path
        d="M29.049 46.1021C33.5764 46.1021 37.2465 42.4618 37.2465 37.9712C37.2465 33.4806 33.5764 29.8402 29.049 29.8402C24.5217 29.8402 20.8516 33.4806 20.8516 37.9712C20.8516 42.4618 24.5217 46.1021 29.049 46.1021Z"
        fill="#CCCCCC"
      />
      <path
        d="M47.9841 32.5505C47.6223 32.5513 47.2757 32.6945 47.0201 32.9485C46.7646 33.2026 46.6211 33.5468 46.6211 33.9056C46.6211 34.2645 46.7646 34.6087 47.0201 34.8627C47.2757 35.1168 47.6223 35.2599 47.9841 35.2608H112.393C112.572 35.2613 112.75 35.2268 112.916 35.1591C113.082 35.0915 113.233 34.992 113.361 34.8664C113.488 34.7408 113.589 34.5916 113.658 34.4272C113.727 34.2628 113.763 34.0866 113.763 33.9085C113.764 33.7304 113.729 33.554 113.66 33.3894C113.592 33.2247 113.492 33.075 113.365 32.9489C113.238 32.8228 113.088 32.7227 112.922 32.6543C112.756 32.586 112.578 32.5507 112.399 32.5505C112.397 32.5505 112.395 32.5505 112.393 32.5505H47.9841Z"
        fill="#CCCCCC"
      />
      <path
        d="M47.9851 40.6815C47.6231 40.6821 47.2762 40.8251 47.0205 41.0792C46.7647 41.3332 46.6211 41.6776 46.6211 42.0366C46.6211 42.3956 46.7647 42.74 47.0205 42.9941C47.2762 43.2481 47.6231 43.3912 47.9851 43.3918H75.7004C76.0627 43.3925 76.4105 43.2505 76.6673 42.9969C76.9241 42.7433 77.0687 42.3989 77.0695 42.0395C77.0703 41.6801 76.9271 41.3351 76.6714 41.0804C76.4157 40.8257 76.0685 40.6822 75.7061 40.6815C75.7042 40.6815 75.7023 40.6815 75.7004 40.6815H47.9851Z"
        fill="#CCCCCC"
      />
      <path
        d="M60.4209 95.8854H15.1133L25.7757 77.5671C25.9668 77.2387 26.2416 76.9661 26.5726 76.7765C26.9036 76.587 27.2791 76.4872 27.6613 76.4872C28.0435 76.4872 28.419 76.587 28.75 76.7765C29.081 76.9661 29.3559 77.2387 29.547 77.5671L34.1018 85.3923L41.8466 72.0868C42.0859 71.6758 42.43 71.3344 42.8444 71.0971C43.2588 70.8597 43.7289 70.7348 44.2075 70.7348C44.686 70.7348 45.1561 70.8597 45.5705 71.0971C45.9849 71.3344 46.329 71.6758 46.5683 72.0868L60.4209 95.8854ZM16.5074 95.0869H59.0268L45.8711 72.486C45.7025 72.1963 45.46 71.9558 45.168 71.7885C44.876 71.6213 44.5447 71.5332 44.2075 71.5332C43.8703 71.5332 43.539 71.6213 43.247 71.7885C42.955 71.9558 42.7125 72.1963 42.5439 72.486L34.1018 86.9892L28.8497 77.9663C28.7293 77.7593 28.5561 77.5875 28.3475 77.468C28.1389 77.3485 27.9023 77.2856 27.6614 77.2856C27.4205 77.2856 27.1838 77.3485 26.9752 77.468C26.7666 77.5875 26.5934 77.7593 26.473 77.9663L16.5074 95.0869Z"
        fill="#9C73F2"
      />
      <path
        d="M118.38 95.8854H73.0723L83.7347 77.5671C83.9258 77.2387 84.2006 76.9661 84.5316 76.7765C84.8626 76.5869 85.2381 76.4871 85.6203 76.4871C86.0025 76.4871 86.378 76.5869 86.709 76.7765C87.04 76.9661 87.3149 77.2387 87.506 77.5671L92.0608 85.3923L99.8056 72.0868C100.045 71.6758 100.389 71.3344 100.803 71.0971C101.218 70.8597 101.688 70.7348 102.166 70.7348C102.645 70.7348 103.115 70.8597 103.53 71.0971C103.944 71.3344 104.288 71.6758 104.527 72.0868L118.38 95.8854ZM74.4664 95.0869H116.986L103.83 72.486C103.661 72.1963 103.419 71.9558 103.127 71.7885C102.835 71.6213 102.504 71.5332 102.166 71.5332C101.829 71.5332 101.498 71.6213 101.206 71.7885C100.914 71.9558 100.671 72.1963 100.503 72.486L92.0608 86.9892L86.8087 77.9663C86.6883 77.7593 86.5151 77.5875 86.3065 77.468C86.0979 77.3485 85.8612 77.2856 85.6203 77.2856C85.3794 77.2856 85.1428 77.3485 84.9342 77.468C84.7256 77.5875 84.5524 77.7593 84.4319 77.9663L74.4664 95.0869Z"
        fill="#9C73F2"
      />
      <path
        d="M60.4646 95.8804H12.1667C11.6332 95.8797 11.1217 95.6692 10.7444 95.295C10.3671 94.9208 10.1549 94.4135 10.1543 93.8843V67.1466C10.1549 66.6174 10.3671 66.11 10.7444 65.7358C11.1217 65.3616 11.6332 65.1511 12.1667 65.1505H60.4646C60.9982 65.1511 61.5097 65.3616 61.8869 65.7358C62.2642 66.11 62.4764 66.6174 62.4771 67.1466V93.8843C62.4764 94.4135 62.2642 94.9208 61.8869 95.295C61.5097 95.6692 60.9982 95.8797 60.4646 95.8804ZM12.1667 65.9489C11.8466 65.9493 11.5396 66.0756 11.3133 66.3001C11.0869 66.5246 10.9596 66.8291 10.9592 67.1466V93.8843C10.9596 94.2018 11.0869 94.5063 11.3133 94.7308C11.5396 94.9553 11.8466 95.0816 12.1667 95.0819H60.4646C60.7848 95.0816 61.0917 94.9553 61.3181 94.7308C61.5444 94.5063 61.6717 94.2018 61.6721 93.8843V67.1466C61.6717 66.829 61.5444 66.5246 61.3181 66.3001C61.0917 66.0755 60.7848 65.9493 60.4646 65.9489L12.1667 65.9489Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
