export default function CSSIcon(){
    return(
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="43" height="43" rx="4.3" fill="white" />
        <path
          d="M9.13672 7.5249L11.387 32.6724L21.4842 35.4749L31.6097 32.6621L33.8617 7.5249H9.13672ZM29.1868 13.2863L28.4378 21.6638L28.44 21.709L28.4369 21.813V21.8121L27.9047 27.6698L27.8461 28.1902L21.4992 29.9376V29.9378L21.4943 29.9421L15.1584 28.1848L14.7294 23.3419H17.8363L18.0568 25.8045L21.5001 26.7334L21.4992 26.7352V26.7348L24.9553 25.7927L25.3205 21.7825H18.0957L18.0337 21.1046L17.8925 19.5164L17.8184 18.6636H25.5951L25.878 15.5448H14.0334L13.9714 14.8673L13.8304 13.2787L13.7561 12.4259H29.2609L29.1868 13.2863Z"
          fill="#9C73F2"
        />
      </svg>
    )
}