export default function DevelopmentIllustration() {
  return (
    <svg
      width="132"
      height="170"
      viewBox="0 0 132 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M130.156 0H1.84431C1.35534 0.00101765 0.886542 0.36581 0.540784 1.01433C0.195026 1.66286 0.000542557 2.54215 0 3.4593V166.541C0.000543253 167.458 0.195034 168.337 0.540792 168.986C0.88655 169.634 1.35534 169.999 1.84431 170H130.156C130.645 169.999 131.113 169.634 131.459 168.986C131.805 168.337 131.999 167.458 132 166.541V3.4593C131.999 2.54215 131.805 1.66286 131.459 1.01433C131.113 0.36581 130.645 0.00101765 130.156 0ZM131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M131.473 166.541C131.473 167.196 131.334 167.824 131.087 168.288C130.84 168.751 130.505 169.011 130.156 169.012H1.84431C1.49497 169.011 1.15997 168.751 0.912949 168.288C0.665926 167.824 0.527086 167.196 0.526946 166.541V3.4593C0.527086 2.80405 0.665926 2.17572 0.912949 1.71238C1.15997 1.24905 1.49497 0.988634 1.84431 0.988372H130.156C130.505 0.988634 130.84 1.24905 131.087 1.71238C131.334 2.17572 131.473 2.80405 131.473 3.4593V166.541Z"
        fill="white"
      />
      <path
        d="M130.977 13.3334H1.02344V14.4445H130.977V13.3334Z"
        fill="#3F3D56"
      />
      <path
        d="M8.4321 10.6434C9.98458 10.6434 11.2431 9.28192 11.2431 7.60246C11.2431 5.923 9.98458 4.56152 8.4321 4.56152C6.87963 4.56152 5.62109 5.923 5.62109 7.60246C5.62109 9.28192 6.87963 10.6434 8.4321 10.6434Z"
        fill="#3F3D56"
      />
      <path
        d="M16.1645 10.6434C17.717 10.6434 18.9755 9.28192 18.9755 7.60246C18.9755 5.923 17.717 4.56152 16.1645 4.56152C14.612 4.56152 13.3535 5.923 13.3535 7.60246C13.3535 9.28192 14.612 10.6434 16.1645 10.6434Z"
        fill="#3F3D56"
      />
      <path
        d="M23.893 10.6434C25.4455 10.6434 26.704 9.28192 26.704 7.60246C26.704 5.923 25.4455 4.56152 23.893 4.56152C22.3406 4.56152 21.082 5.923 21.082 7.60246C21.082 9.28192 22.3406 10.6434 23.893 10.6434Z"
        fill="#3F3D56"
      />
      <path
        d="M19.8432 44.1347C19.7226 44.1347 19.6063 44.0863 19.5166 43.999L14.2846 38.9152C14.2368 38.8687 14.1979 38.8125 14.1701 38.7497C14.1423 38.6869 14.1263 38.6189 14.1229 38.5494C14.1194 38.4799 14.1286 38.4104 14.15 38.3447C14.1715 38.2791 14.2046 38.2187 14.2476 38.167L18.947 32.507C18.99 32.4552 19.042 32.4131 19.1 32.3831C19.158 32.3531 19.221 32.3357 19.2852 32.332C19.3494 32.3283 19.4137 32.3383 19.4743 32.3614C19.535 32.3846 19.5908 32.4204 19.6387 32.4669C19.6865 32.5134 19.7255 32.5696 19.7532 32.6324C19.781 32.6952 19.797 32.7632 19.8005 32.8327C19.8039 32.9022 19.7947 32.9717 19.7733 33.0373C19.7519 33.103 19.7188 33.1634 19.6758 33.2151L15.3036 38.4809L20.1713 43.2107C20.245 43.2824 20.297 43.3767 20.3202 43.481C20.3433 43.5853 20.3367 43.6947 20.3012 43.7949C20.2656 43.895 20.2028 43.9811 20.121 44.0418C20.0392 44.1025 19.9423 44.1349 19.8432 44.1347Z"
        fill="#9C73F2"
      />
      <path
        d="M26.9788 44.1269C26.8781 44.1268 26.7799 44.0932 26.6975 44.0306C26.6151 43.9679 26.5526 43.8794 26.5185 43.7769C26.4843 43.6745 26.4801 43.5631 26.5065 43.4581C26.533 43.353 26.5887 43.2592 26.6661 43.1896L31.6981 38.6655L27.5162 33.2218C27.4749 33.1683 27.4438 33.1065 27.4246 33.04C27.4055 32.9735 27.3986 32.9036 27.4045 32.8342C27.4103 32.7648 27.4287 32.6973 27.4587 32.6357C27.4887 32.574 27.5296 32.5193 27.5792 32.4748C27.6287 32.4303 27.6859 32.3968 27.7474 32.3762C27.8089 32.3556 27.8736 32.3483 27.9377 32.3548C28.0019 32.3613 28.0642 32.3814 28.1211 32.414C28.178 32.4466 28.2285 32.491 28.2695 32.5447L32.7645 38.3959C32.8056 38.4494 32.8366 38.5111 32.8557 38.5776C32.8747 38.644 32.8815 38.7139 32.8755 38.7831C32.8696 38.8524 32.8511 38.9198 32.8212 38.9813C32.7912 39.0429 32.7503 39.0974 32.7008 39.1419L27.292 44.0046C27.2041 44.0839 27.0932 44.1272 26.9788 44.1269Z"
        fill="#9C73F2"
      />
      <path
        d="M88.4614 51.3982L10.6574 51.2905C9.45769 51.2874 8.30812 50.7696 7.46089 49.8507C6.61366 48.9319 6.13795 47.6869 6.13813 46.3891L6.15746 30.0407C6.16035 28.7428 6.63899 27.4992 7.48839 26.5827C8.33779 25.6662 9.48858 25.1516 10.6883 25.1518L88.4922 25.2594C89.6919 25.2626 90.8415 25.7804 91.6887 26.6992C92.536 27.6181 93.0117 28.863 93.0115 30.1609L92.9922 46.5093C92.9893 47.8071 92.5106 49.0507 91.6612 49.9673C90.8118 50.8838 89.6611 51.3984 88.4614 51.3982ZM10.6875 25.8582C9.66091 25.8581 8.67621 26.2984 7.9494 27.0827C7.22259 27.8669 6.81301 28.931 6.81052 30.0415L6.79119 46.39C6.79105 47.5005 7.1981 48.5658 7.92306 49.352C8.64802 50.1383 9.63167 50.5814 10.6582 50.5841L88.4622 50.6917C89.4888 50.6919 90.4735 50.2515 91.2003 49.4673C91.9271 48.683 92.3367 47.6189 92.3392 46.5084L92.3585 30.16C92.3586 29.0494 91.9515 27.9842 91.2266 27.1979C90.5016 26.4117 89.518 25.9686 88.4914 25.9659L10.6875 25.8582Z"
        fill="#3F3D56"
      />
      <path
        d="M55.9414 35.8057L42.8806 35.7877C42.5342 35.7872 42.2022 35.6379 41.9576 35.3726C41.713 35.1072 41.5758 34.7477 41.5762 34.373C41.5767 33.9982 41.7147 33.639 41.9599 33.3744C42.2052 33.1098 42.5376 32.9614 42.884 32.9619L55.9447 32.9799C56.2911 32.9804 56.6231 33.1297 56.8678 33.395C57.1124 33.6604 57.2496 34.0199 57.2491 34.3946C57.2487 34.7694 57.1107 35.1286 56.8654 35.3932C56.6202 35.6578 56.2878 35.8062 55.9414 35.8057Z"
        fill="#E6E6E6"
      />
      <path
        d="M84.014 43.6159L42.5461 43.5585C42.1997 43.558 41.8677 43.4087 41.6231 43.1434C41.3785 42.8781 41.2413 42.5185 41.2417 42.1438C41.2422 41.7691 41.3802 41.4099 41.6255 41.1453C41.8707 40.8806 42.2031 40.7322 42.5495 40.7327L84.0174 40.7901C84.3638 40.7906 84.6958 40.9399 84.9404 41.2052C85.185 41.4705 85.3222 41.8301 85.3218 42.2048C85.3213 42.5795 85.1833 42.9387 84.9381 43.2034C84.6928 43.468 84.3604 43.6164 84.014 43.6159Z"
        fill="#E6E6E6"
      />
      <path
        d="M15.4348 62.6498L9.07979 62.6291C8.21376 62.6263 7.50961 63.3835 7.50701 64.3203L7.5015 66.301C7.4989 67.2379 8.19883 67.9997 9.06486 68.0025L15.4198 68.0232C16.2859 68.026 16.99 67.2688 16.9926 66.3319L16.9982 64.3512C17.0008 63.4144 16.3008 62.6526 15.4348 62.6498Z"
        fill="#BEBEBE"
      />
      <path
        d="M67.175 62.8179L60.8201 62.7972C59.954 62.7944 59.2498 63.5516 59.2472 64.4885L59.2417 66.4692C59.2391 67.406 59.9391 68.1678 60.8051 68.1706L67.1601 68.1913C68.0261 68.1941 68.7303 67.4369 68.7329 66.5L68.7384 64.5193C68.741 63.5825 68.0411 62.8207 67.175 62.8179Z"
        fill="#9C73F2"
      />
      <path
        d="M81.8352 62.866L75.4802 62.8453C74.6142 62.8425 73.91 63.5997 73.9074 64.5366L73.9019 66.5172C73.8993 67.4541 74.5992 68.2159 75.4652 68.2187L81.8202 68.2394C82.6863 68.2422 83.3904 67.485 83.393 66.5481L83.3985 64.5674C83.4011 63.6306 82.7012 62.8688 81.8352 62.866Z"
        fill="#BEBEBE"
      />
      <path
        d="M53.1066 62.3248L23.1579 62.2274C22.2919 62.2246 21.5877 62.9818 21.5851 63.9186L21.5796 65.8993C21.577 66.8362 22.277 67.5979 23.143 67.6008L53.0917 67.6981C53.9577 67.701 54.6618 66.9438 54.6644 66.0069L54.67 64.0262C54.6726 63.0893 53.9726 62.3276 53.1066 62.3248Z"
        fill="#BEBEBE"
      />
      <path
        d="M122.097 62.549L92.1481 62.4516C91.2821 62.4488 90.578 63.206 90.5754 64.1429L90.5699 66.1236C90.5673 67.0604 91.2672 67.8222 92.1332 67.825L122.082 67.9224C122.948 67.9252 123.652 67.168 123.655 66.2312L123.66 64.2504C123.663 63.3136 122.963 62.5518 122.097 62.549Z"
        fill="#BEBEBE"
      />
      <path
        d="M15.5891 74.7786L9.23412 74.7579C8.36809 74.7551 7.66391 75.5123 7.6613 76.4491L7.6558 78.4298C7.6532 79.3667 8.35316 80.1285 9.21919 80.1313L15.5742 80.1519C16.4402 80.1548 17.1443 79.3976 17.1469 78.4607L17.1524 76.48C17.1551 75.5431 16.4551 74.7814 15.5891 74.7786Z"
        fill="#BEBEBE"
      />
      <path
        d="M67.3274 74.9461L60.9724 74.9254C60.1063 74.9226 59.4022 75.6798 59.3996 76.6166L59.3941 78.5973C59.3915 79.5342 60.0914 80.296 60.9574 80.2988L67.3124 80.3194C68.1785 80.3223 68.8826 79.5651 68.8852 78.6282L68.8907 76.6475C68.8933 75.7106 68.1934 74.9489 67.3274 74.9461Z"
        fill="#9C73F2"
      />
      <path
        d="M81.9895 74.9935L75.6345 74.9729C74.7684 74.9701 74.0643 75.7273 74.0617 76.6641L74.0562 78.6448C74.0536 79.5817 74.7535 80.3434 75.6195 80.3463L81.9745 80.3669C82.8405 80.3697 83.5447 79.6125 83.5473 78.6757L83.5528 76.695C83.5554 75.7581 82.8555 74.9964 81.9895 74.9935Z"
        fill="#BEBEBE"
      />
      <path
        d="M53.255 74.4522L23.3064 74.3548C22.4404 74.352 21.7362 75.1092 21.7336 76.0461L21.7281 78.0268C21.7255 78.9636 22.4254 79.7254 23.2915 79.7282L53.2401 79.8256C54.1061 79.8284 54.8103 79.0712 54.8129 78.1343L54.8184 76.1536C54.821 75.2168 54.121 74.455 53.255 74.4522Z"
        fill="#BEBEBE"
      />
      <path
        d="M122.245 74.6753L92.2966 74.5781C91.4306 74.5753 90.7264 75.3325 90.7238 76.2693L90.7183 78.25C90.7157 79.1869 91.4156 79.9487 92.2817 79.9515L122.23 80.0487C123.096 80.0515 123.801 79.2944 123.803 78.3575L123.809 76.3768C123.811 75.4399 123.111 74.6782 122.245 74.6753Z"
        fill="#BEBEBE"
      />
      <path
        d="M42.966 86.9948L36.611 86.9741C35.745 86.9713 35.0409 87.7285 35.0383 88.6653L35.0328 90.646C35.0301 91.5829 35.7301 92.3447 36.5961 92.3475L42.9511 92.3681C43.8171 92.371 44.5213 91.6138 44.5239 90.6769L44.5294 88.6962C44.532 87.7593 43.832 86.9976 42.966 86.9948Z"
        fill="#BEBEBE"
      />
      <path
        d="M94.7082 87.1637L88.3532 87.143C87.4872 87.1402 86.783 87.8974 86.7804 88.8343L86.7749 90.815C86.7723 91.7518 87.4723 92.5136 88.3383 92.5164L94.6933 92.5371C95.5593 92.5399 96.2635 91.7827 96.2661 90.8458L96.2716 88.8651C96.2742 87.9283 95.5742 87.1665 94.7082 87.1637Z"
        fill="#9C73F2"
      />
      <path
        d="M109.366 87.2111L103.011 87.1904C102.145 87.1876 101.441 87.9448 101.439 88.8816L101.433 90.8623C101.431 91.7992 102.13 92.561 102.996 92.5638L109.352 92.5844C110.218 92.5872 110.922 91.8301 110.924 90.8932L110.93 88.9125C110.932 87.9756 110.232 87.2139 109.366 87.2111Z"
        fill="#BEBEBE"
      />
      <path
        d="M80.6359 86.6693L50.6872 86.5719C49.8212 86.5691 49.117 87.3262 49.1144 88.2631L49.1089 90.2438C49.1063 91.1807 49.8063 91.9424 50.6723 91.9452L80.6209 92.0426C81.487 92.0454 82.1911 91.2882 82.1937 90.3514L82.1992 88.3707C82.2018 87.4338 81.5019 86.6721 80.6359 86.6693Z"
        fill="#BEBEBE"
      />
      <path
        d="M15.2864 122.45L8.93138 122.43C8.06536 122.427 7.36117 123.184 7.35857 124.121L7.35306 126.101C7.35046 127.038 8.05043 127.8 8.91645 127.803L15.2714 127.824C16.1375 127.826 16.8416 127.069 16.8442 126.132L16.8497 124.152C16.8523 123.215 16.1524 122.453 15.2864 122.45Z"
        fill="#9C73F2"
      />
      <path
        d="M29.9465 122.498L23.5915 122.477C22.7255 122.474 22.0213 123.231 22.0187 124.168L22.0132 126.149C22.0106 127.086 22.7105 127.847 23.5766 127.85L29.9316 127.871C30.7976 127.874 31.5018 127.117 31.5044 126.18L31.5099 124.199C31.5125 123.262 30.8125 122.5 29.9465 122.498Z"
        fill="#BEBEBE"
      />
      <path
        d="M70.2023 123.077L40.2536 122.98C39.3876 122.977 38.6834 123.734 38.6808 124.671L38.6753 126.652C38.6727 127.589 39.3727 128.35 40.2387 128.353L70.1873 128.451C71.0534 128.453 71.7575 127.696 71.7602 126.759L71.7657 124.779C71.7683 123.842 71.0683 123.08 70.2023 123.077Z"
        fill="#BEBEBE"
      />
      <path
        d="M53.0734 110.445L46.7185 110.425C45.8524 110.422 45.1483 111.179 45.1457 112.116L45.1402 114.097C45.1376 115.034 45.8375 115.795 46.7035 115.798L53.0585 115.819C53.9245 115.822 54.6287 115.064 54.6313 114.128L54.6368 112.147C54.6394 111.21 53.9395 110.448 53.0734 110.445Z"
        fill="#9C73F2"
      />
      <path
        d="M67.7395 110.492L61.3845 110.472C60.5185 110.469 59.8143 111.226 59.8117 112.163L59.8062 114.143C59.8036 115.08 60.5035 115.842 61.3696 115.845L67.7246 115.866C68.5906 115.868 69.2948 115.111 69.2974 114.174L69.3029 112.194C69.3055 111.257 68.6055 110.495 67.7395 110.492Z"
        fill="#9C73F2"
      />
      <path
        d="M38.9991 110.848L9.05049 110.75C8.18446 110.748 7.48031 111.505 7.47771 112.442L7.47221 114.422C7.4696 115.359 8.16953 116.121 9.03556 116.124L38.9842 116.221C39.8502 116.224 40.5544 115.467 40.557 114.53L40.5625 112.549C40.5651 111.612 39.8652 110.851 38.9991 110.848Z"
        fill="#BEBEBE"
      />
      <path
        d="M107.988 111.071L78.0388 110.974C77.1728 110.971 76.4686 111.728 76.466 112.665L76.4605 114.646C76.4579 115.583 77.1579 116.344 78.0239 116.347L107.973 116.444C108.839 116.447 109.543 115.69 109.545 114.753L109.551 112.772C109.554 111.836 108.854 111.074 107.988 111.071Z"
        fill="#BEBEBE"
      />
      <path
        d="M42.9309 98.2847L36.5759 98.264C35.7099 98.2612 35.0057 99.0184 35.0031 99.9553L34.9976 101.936C34.995 102.873 35.695 103.635 36.561 103.637L42.916 103.658C43.782 103.661 44.4861 102.904 44.4887 101.967L44.4942 99.9861C44.4968 99.0493 43.7969 98.2875 42.9309 98.2847Z"
        fill="#BEBEBE"
      />
      <path
        d="M94.6731 98.4528L88.3181 98.4321C87.452 98.4293 86.7479 99.1865 86.7453 100.123L86.7398 102.104C86.7372 103.041 87.4371 103.803 88.3031 103.805L94.6582 103.826C95.5242 103.829 96.2283 103.072 96.2309 102.135L96.2364 100.154C96.239 99.2173 95.5391 98.4556 94.6731 98.4528Z"
        fill="#9C73F2"
      />
      <path
        d="M109.333 98.5012L102.978 98.4806C102.112 98.4777 101.408 99.2349 101.405 100.172L101.4 102.153C101.397 103.089 102.097 103.851 102.963 103.854L109.318 103.875C110.184 103.877 110.888 103.12 110.891 102.183L110.897 100.203C110.899 99.2658 110.199 98.504 109.333 98.5012Z"
        fill="#BEBEBE"
      />
      <path
        d="M80.5968 98.8547L50.6481 98.7573C49.7821 98.7545 49.078 99.5117 49.0754 100.449L49.0699 102.429C49.0673 103.366 49.7672 104.128 50.6332 104.131L80.5819 104.228C81.4479 104.231 82.152 103.474 82.1547 102.537L82.1602 100.556C82.1628 99.6192 81.4628 98.8575 80.5968 98.8547Z"
        fill="#BEBEBE"
      />
      <path
        d="M26.0479 103.142C27.3466 103.553 28.7075 102.747 29.0875 101.342C29.4674 99.9374 28.7226 98.4652 27.4239 98.0542C26.1251 97.6432 24.7642 98.4489 24.3843 99.8539C24.0043 101.259 24.7491 102.731 26.0479 103.142Z"
        fill="#9C73F2"
      />
      <path
        d="M26.0811 91.9512C27.3798 92.3622 28.7407 91.5565 29.1207 90.1515C29.5006 88.7465 28.7558 87.2743 27.4571 86.8633C26.1583 86.4522 24.7974 87.258 24.4175 88.663C24.0375 90.0679 24.7823 91.5401 26.0811 91.9512Z"
        fill="#9C73F2"
      />
      <path
        d="M62.9922 153.887H67.7668V157.303H59.6477V145.856H67.7668V149.271H62.9922H62.6922V149.571V153.587V153.887H62.9922ZM74.8857 153.113H70.1111V145.856H78.2444V148.748H73.4585H73.1585V149.048V149.746V150.046H73.4585H78.2331V157.478H70.1111V154.411H74.8857H75.1857V154.111V153.413V153.113H74.8857ZM85.3492 153.113H80.5746V145.856H88.7221V148.748H83.922H83.622V149.048V149.746V150.046H83.922H88.6966V157.478H80.5746V154.411H85.3492H85.6492V154.111V153.413V153.113H85.3492Z"
        fill="black"
        stroke="white"
        strokeWidth="0.6"
      />
      <path
        d="M10.2352 154.058V157.578H7.36406V146.867H10.2352V150.334V150.534H10.4352H13.4266H13.6266V150.334V146.867H16.4978V157.578H13.6266V154.058V153.858H13.4266H10.4352H10.2352V154.058ZM20.9989 150.152H18.3182V146.867H26.9508V150.152H24.2701H24.0701V150.352V157.578H21.202V150.352V150.152H21.002H20.9989ZM34.2752 150.666L36.3154 146.867H39.4084V157.578H36.5495V152.271V151.516L36.1757 152.172L34.0712 155.866L31.9639 152.172L31.5901 151.517V152.271V157.578H28.7866V146.867H31.8797L33.9229 150.666L34.0991 150.994L34.2752 150.666ZM44.5059 154.303H48.9175V157.574H41.444V146.867H44.3059V154.103V154.303H44.5059Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
    </svg>
  );
}
