export default function CubeIcon() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="4.3" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.27734 6C5.72506 6 5.27734 6.44771 5.27734 7V15.1901C5.27734 15.7423 5.72506 16.1901 6.27734 16.1901H14.4684C15.0207 16.1901 15.4684 15.7423 15.4684 15.1901V7C15.4684 6.44772 15.0207 6 14.4684 6H6.27734ZM14.0374 7.43045H6.70703V14.7601H14.0374V7.43045Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.27734 16.9043C5.72506 16.9043 5.27734 17.352 5.27734 17.9043V26.0943C5.27734 26.6466 5.72506 27.0943 6.27734 27.0943H14.4684C15.0207 27.0943 15.4684 26.6466 15.4684 26.0943V17.9043C15.4684 17.352 15.0207 16.9043 14.4684 16.9043H6.27734ZM14.0374 18.3344H6.70703V25.6641H14.0374V18.3344Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.27734 27.8101C5.72506 27.8101 5.27734 28.2578 5.27734 28.8101V37.0001C5.27734 37.5524 5.72506 38.0001 6.27734 38.0001H14.4684C15.0207 38.0001 15.4684 37.5524 15.4684 37.0001V28.8101C15.4684 28.2578 15.0207 27.8101 14.4684 27.8101H6.27734ZM14.0374 29.2399H6.70703V36.5696H14.0374V29.2399Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1836 6C16.6313 6 16.1836 6.44771 16.1836 7V15.1901C16.1836 15.7423 16.6313 16.1901 17.1836 16.1901H25.3746C25.9269 16.1901 26.3746 15.7423 26.3746 15.1901V7C26.3746 6.44772 25.9269 6 25.3746 6H17.1836ZM24.9437 7.43045H17.6133V14.7601H24.9437V7.43045Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1836 16.9048C16.6313 16.9048 16.1836 17.3525 16.1836 17.9048V26.0948C16.1836 26.6471 16.6313 27.0948 17.1836 27.0948H25.3746C25.9269 27.0948 26.3746 26.6471 26.3746 26.0948V17.9048C26.3746 17.3525 25.9269 16.9048 25.3746 16.9048H17.1836ZM24.9437 18.3349H17.6133V25.6646H24.9437V18.3349Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.1836 27.8101C16.6313 27.8101 16.1836 28.2578 16.1836 28.8101V37.0001C16.1836 37.5524 16.6313 38.0001 17.1836 38.0001H25.3746C25.9269 38.0001 26.3746 37.5524 26.3746 37.0001V28.8101C26.3746 28.2578 25.9269 27.8101 25.3746 27.8101H17.1836ZM24.9437 29.2399H17.6133V36.5696H24.9437V29.2399Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.0859 6C27.5337 6 27.0859 6.44771 27.0859 7V15.1901C27.0859 15.7423 27.5337 16.1901 28.0859 16.1901H36.277C36.8293 16.1901 37.277 15.7423 37.277 15.1901V7C37.277 6.44772 36.8293 6 36.277 6H28.0859ZM35.846 7.43045H28.5156V14.7601H35.846V7.43045Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.0859 16.9048C27.5337 16.9048 27.0859 17.3525 27.0859 17.9048V26.0948C27.0859 26.6471 27.5337 27.0948 28.0859 27.0948H36.277C36.8293 27.0948 37.277 26.6471 37.277 26.0948V17.9048C37.277 17.3525 36.8293 16.9048 36.277 16.9048H28.0859ZM35.846 18.3349H28.5156V25.6646H35.846V18.3349Z" fill="#9C73F2" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.0859 27.8101C27.5337 27.8101 27.0859 28.2578 27.0859 28.8101V37.0001C27.0859 37.5524 27.5337 38.0001 28.0859 38.0001H36.277C36.8293 38.0001 37.277 37.5524 37.277 37.0001V28.8101C37.277 28.2578 36.8293 27.8101 36.277 27.8101H28.0859ZM35.846 29.2399H28.5156V36.5696H35.846V29.2399Z" fill="#9C73F2" />
        </svg>

    )
}