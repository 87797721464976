export default function CrossIconOutlined() {
  return (
    <svg
      width="74"
      height="73"
      viewBox="0 0 74 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8058 50.695C22.4637 50.3527 22.2715 49.8886 22.2715 49.4047C22.2715 48.9208 22.4637 48.4567 22.8058 48.1144L34.4201 36.5001L22.8058 24.8858C22.4734 24.5416 22.2894 24.0806 22.2936 23.6021C22.2977 23.1236 22.4897 22.6659 22.8281 22.3275C23.1664 21.9891 23.6242 21.7972 24.1027 21.793C24.5812 21.7889 25.0422 21.9728 25.3864 22.3053L37.0007 33.9196L48.615 22.3053C48.9592 21.9728 49.4202 21.7889 49.8987 21.793C50.3772 21.7972 50.8349 21.9891 51.1733 22.3275C51.5117 22.6659 51.7036 23.1236 51.7077 23.6021C51.7119 24.0806 51.528 24.5416 51.1955 24.8858L39.5812 36.5001L51.1955 48.1144C51.528 48.4586 51.7119 48.9196 51.7077 49.3981C51.7036 49.8766 51.5117 50.3344 51.1733 50.6727C50.8349 51.0111 50.3772 51.203 49.8987 51.2072C49.4202 51.2114 48.9592 51.0274 48.615 50.695L37.0007 39.0807L25.3864 50.695C25.0441 51.0371 24.58 51.2293 24.0961 51.2293C23.6122 51.2293 23.1481 51.0371 22.8058 50.695Z"
        fill="#C73636"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37 73C57.159 73 73.5 56.659 73.5 36.5C73.5 16.3411 57.159 0 37 0C16.8411 0 0.5 16.3411 0.5 36.5C0.5 56.659 16.8411 73 37 73ZM37 69.35C55.1423 69.35 69.85 54.6423 69.85 36.5C69.85 18.3577 55.1423 3.65 37 3.65C18.8577 3.65 4.15 18.3577 4.15 36.5C4.15 54.6423 18.8577 69.35 37 69.35Z"
        fill="#C73636"
      />
    </svg>
  );
}
