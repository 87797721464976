export default function ArrowIcon() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.469798 7.78741C0.176834 8.08024 0.176719 8.55511 0.469542 8.84807C0.762364 9.14104 1.23724 9.14115 1.5302 8.84833L0.469798 7.78741ZM9.06966 1.00193C9.06976 0.587715 8.73406 0.251848 8.31985 0.251747L1.56985 0.250115C1.15563 0.250015 0.819765 0.58572 0.819664 0.999933C0.819564 1.41415 1.15527 1.75001 1.56948 1.75011L7.56948 1.75157L7.56803 7.75157C7.56793 8.16578 7.90364 8.50165 8.31785 8.50175C8.73206 8.50185 9.06793 8.16614 9.06803 7.75193L9.06966 1.00193ZM1.5302 8.84833L8.84987 1.53221L7.78946 0.471289L0.469798 7.78741L1.5302 8.84833Z"
        fill="white"
      />
    </svg>
  )
}
