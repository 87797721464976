export default function ReadyIllustration() {
  return (
    <svg
      width="135"
      height="175"
      viewBox="0 0 135 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.917 138.543C124.869 138.543 124.839 138.541 124.829 138.541L119.152 138.541V137.68H124.849C124.981 137.684 127.673 137.763 130.153 134.665C133.848 130.047 136.883 118.617 130.354 90.3848C121.575 52.4224 128.897 18.8846 132.453 6.22755C132.628 5.60135 132.659 4.94179 132.544 4.30098C132.429 3.66017 132.171 3.05566 131.79 2.53523C131.409 2.0148 130.916 1.59269 130.35 1.30227C129.783 1.01185 129.159 0.861074 128.526 0.861848H43.7389C43.0943 0.862738 42.459 1.0201 41.8845 1.32116C41.31 1.62223 40.8126 2.05851 40.4326 2.59459C38.9264 4.72775 36.8594 8.82573 35.566 16.2356L34.7422 16.0832C36.0636 8.51242 38.1978 4.29388 39.7555 2.08792C40.2133 1.44196 40.8126 0.916255 41.5047 0.553477C42.1968 0.190699 42.9623 0.00107335 43.7389 2.34509e-06H128.526C129.288 -0.000753293 130.041 0.181113 130.724 0.531232C131.406 0.881352 132.001 1.39013 132.459 2.01738C132.918 2.64462 133.229 3.37314 133.368 4.14539C133.506 4.91763 133.468 5.71243 133.257 6.46701C129.72 19.0585 122.436 52.4221 131.169 90.1853C137.789 118.815 134.607 130.493 130.771 135.248C128.241 138.384 125.453 138.543 124.917 138.543Z"
        fill="#E6E6E6"
      />
      <path
        d="M109.643 154.271C109.596 154.271 109.566 154.27 109.556 154.269L103.878 154.27V153.408H109.576C109.709 153.412 112.399 153.491 114.879 150.393C118.575 145.776 121.61 134.346 115.081 106.113C106.302 68.1509 113.624 34.6132 117.179 21.9561C117.355 21.3299 117.386 20.6703 117.271 20.0295C117.156 19.3887 116.898 18.7842 116.517 18.2637C116.136 17.7433 115.643 17.3212 115.076 17.0308C114.51 16.7404 113.885 16.5896 113.252 16.5904H28.4656C27.8209 16.5913 27.1856 16.7486 26.6111 17.0497C26.0367 17.3507 25.5392 17.787 25.1592 18.3231C23.653 20.4563 21.586 24.5542 20.2927 31.9641L19.4688 31.8118C20.7902 24.2409 22.9244 20.0224 24.4821 17.8164C24.9399 17.1705 25.5392 16.6448 26.2313 16.282C26.9234 15.9192 27.6889 15.7296 28.4656 15.7285H113.252C114.015 15.7278 114.767 15.9096 115.45 16.2597C116.133 16.6099 116.727 17.1186 117.186 17.7459C117.645 18.3731 117.956 19.1017 118.094 19.8739C118.233 20.6461 118.195 21.441 117.984 22.1955C114.446 34.787 107.162 68.1507 115.895 105.914C122.516 134.543 119.333 146.221 115.497 150.976C112.967 154.112 110.18 154.271 109.643 154.271Z"
        fill="#CCCCCC"
      />
      <g filter="url(#filter0_d_899_6901)">
        <path
          d="M94.3657 170C94.3182 170 94.2884 169.999 94.278 169.998L10.7981 169.999C9.99439 170 9.20269 169.798 8.49228 169.411C7.78187 169.024 7.17441 168.464 6.72306 167.779C6.27172 167.094 5.99024 166.306 5.90326 165.483C5.81628 164.661 5.92645 163.829 6.22413 163.06C9.25305 155.269 13.3314 136.576 6.61401 98.9219C-1.31799 54.4592 5.78326 38.3903 9.20451 33.5453C9.66231 32.8994 10.2616 32.3736 10.9537 32.0109C11.6459 31.6481 12.4114 31.4585 13.188 31.4574H97.9747C98.7374 31.4566 99.4899 31.6385 100.173 31.9886C100.855 32.3387 101.45 32.8475 101.908 33.4748C102.367 34.102 102.678 34.8305 102.817 35.6028C102.955 36.375 102.917 37.1698 102.706 37.9244C99.1689 50.5159 91.8848 83.8795 100.618 121.643C107.238 150.272 104.056 161.95 100.22 166.705C97.6898 169.841 94.9021 170 94.3657 170ZM13.188 32.3192C12.5434 32.3201 11.9081 32.4775 11.3336 32.7786C10.7591 33.0796 10.2616 33.5159 9.88158 34.052C6.52378 38.8071 -0.436144 54.6317 7.43729 98.7662C13.8544 134.737 10.7421 153.758 7.00122 163.38C6.7491 164.015 6.65447 164.705 6.72586 165.387C6.79725 166.069 7.03243 166.721 7.41016 167.286C7.7847 167.857 8.28946 168.324 8.88016 168.646C9.47085 168.969 10.1294 169.138 10.7981 169.137H94.2985C94.4303 169.144 97.1219 169.22 99.6018 166.122C103.297 161.505 106.332 150.075 99.8033 121.842C91.0243 83.8797 98.3464 50.342 101.902 37.685C102.077 37.0588 102.108 36.3992 101.993 35.7584C101.878 35.1176 101.62 34.5131 101.239 33.9926C100.858 33.4722 100.365 33.0501 99.7986 32.7597C99.232 32.4693 98.6076 32.3185 97.9747 32.3192H13.188Z"
          fill="white"
        />
        <path
          d="M13.188 32.3192C12.5434 32.3201 11.9081 32.4775 11.3336 32.7786C10.7591 33.0796 10.2616 33.5159 9.88158 34.052C6.52378 38.8071 -0.436144 54.6317 7.43729 98.7662C13.8544 134.737 10.7421 153.758 7.00122 163.38C6.7491 164.015 6.65447 164.705 6.72586 165.387C6.79725 166.069 7.03243 166.721 7.41016 167.286C7.7847 167.857 8.28946 168.324 8.88016 168.646C9.47085 168.969 10.1294 169.138 10.7981 169.137H94.2985C94.4303 169.144 97.1219 169.22 99.6018 166.122C103.297 161.505 106.332 150.075 99.8033 121.842C91.0243 83.8797 98.3464 50.342 101.902 37.685C102.077 37.0588 102.108 36.3992 101.993 35.7584C101.878 35.1176 101.62 34.5131 101.239 33.9926C100.858 33.4722 100.365 33.0501 99.7986 32.7597C99.232 32.4693 98.6076 32.3185 97.9747 32.3192H13.188Z"
          fill="white"
        />
      </g>
      <path
        d="M83.5657 81.1784C86.4216 81.1784 88.7369 78.8556 88.7369 75.9903C88.7369 73.1249 86.4216 70.8021 83.5657 70.8021C80.7097 70.8021 78.3945 73.1249 78.3945 75.9903C78.3945 78.8556 80.7097 81.1784 83.5657 81.1784Z"
        fill="#9C73F2"
      />
      <path
        d="M83.0552 77.9695C82.9388 77.9697 82.8256 77.9319 82.7325 77.8619L82.7267 77.8576L81.5114 76.9241C81.455 76.8809 81.4075 76.8269 81.3719 76.7653C81.3362 76.7037 81.313 76.6356 81.3036 76.565C81.2942 76.4944 81.2987 76.4226 81.317 76.3538C81.3353 76.2849 81.367 76.2204 81.4102 76.1638C81.4534 76.1073 81.5072 76.0598 81.5687 76.0242C81.6302 75.9885 81.6981 75.9654 81.7685 75.9562C81.839 75.9469 81.9105 75.9517 81.9791 75.9702C82.0477 75.9887 82.1119 76.0206 82.1682 76.0641L82.9553 76.6697L84.8155 74.2358C84.8586 74.1793 84.9124 74.1319 84.9738 74.0963C85.0352 74.0608 85.103 74.0377 85.1733 74.0283C85.2436 74.019 85.315 74.0237 85.3835 74.0421C85.452 74.0605 85.5162 74.0922 85.5725 74.1355C85.686 74.223 85.7605 74.3524 85.7793 74.4947C85.798 74.6371 85.7597 74.7811 85.6727 74.8952L83.4847 77.7581C83.4341 77.824 83.369 77.8774 83.2945 77.9141C83.22 77.9507 83.1381 77.9697 83.0552 77.9695Z"
        fill="white"
      />
      <path
        d="M83.5657 96.3438C86.4216 96.3438 88.7369 94.021 88.7369 91.1557C88.7369 88.2903 86.4216 85.9675 83.5657 85.9675C80.7097 85.9675 78.3945 88.2903 78.3945 91.1557C78.3945 94.021 80.7097 96.3438 83.5657 96.3438Z"
        fill="#9C73F2"
      />
      <path
        d="M83.0552 93.1349C82.9388 93.1351 82.8256 93.0973 82.7325 93.0273L82.7267 93.023L81.5114 92.0895C81.455 92.0463 81.4075 91.9923 81.3719 91.9307C81.3362 91.8691 81.313 91.801 81.3036 91.7304C81.2942 91.6598 81.2987 91.588 81.317 91.5192C81.3353 91.4503 81.367 91.3858 81.4102 91.3292C81.4534 91.2727 81.5072 91.2252 81.5687 91.1896C81.6302 91.154 81.6981 91.1308 81.7685 91.1216C81.839 91.1123 81.9105 91.1171 81.9791 91.1356C82.0477 91.1541 82.1119 91.186 82.1682 91.2295L82.9553 91.8351L84.8155 89.4012C84.8586 89.3447 84.9124 89.2973 84.9738 89.2618C85.0352 89.2262 85.103 89.2031 85.1733 89.1937C85.2436 89.1844 85.315 89.1891 85.3835 89.2075C85.452 89.2259 85.5162 89.2576 85.5725 89.3009C85.686 89.3884 85.7605 89.5178 85.7793 89.6601C85.798 89.8025 85.7597 89.9465 85.6727 90.0606L83.4847 92.9235C83.4341 92.9894 83.369 93.0428 83.2945 93.0795C83.22 93.1161 83.1381 93.1351 83.0552 93.1349Z"
        fill="white"
      />
      <path
        d="M86.3548 113.904C89.2107 113.904 91.5259 111.581 91.5259 108.715C91.5259 105.85 89.2107 103.527 86.3548 103.527C83.4988 103.527 81.1836 105.85 81.1836 108.715C81.1836 111.581 83.4988 113.904 86.3548 113.904Z"
        fill="#9C73F2"
      />
      <path
        d="M85.8403 110.695C85.724 110.695 85.6107 110.657 85.5176 110.587L85.5119 110.583L84.2966 109.649C84.2401 109.606 84.1927 109.552 84.157 109.491C84.1213 109.429 84.0982 109.361 84.0887 109.29C84.0793 109.22 84.0839 109.148 84.1022 109.079C84.1205 109.01 84.1521 108.946 84.1953 108.889C84.2385 108.833 84.2924 108.785 84.3539 108.75C84.4154 108.714 84.4833 108.691 84.5537 108.682C84.6241 108.672 84.6957 108.677 84.7642 108.696C84.8328 108.714 84.8971 108.746 84.9533 108.789L85.7405 109.395L87.6007 106.961C87.6438 106.905 87.6976 106.857 87.759 106.822C87.8204 106.786 87.8882 106.763 87.9584 106.754C88.0287 106.744 88.1002 106.749 88.1687 106.767C88.2371 106.786 88.3014 106.818 88.3576 106.861C88.4711 106.948 88.5457 107.078 88.5644 107.22C88.5832 107.362 88.5448 107.506 88.4579 107.621L86.2698 110.483C86.2192 110.549 86.1542 110.603 86.0797 110.639C86.0052 110.676 85.9233 110.695 85.8403 110.695Z"
        fill="white"
      />
      <path
        d="M63.1853 79.3427H13.7864C12.7822 79.3427 11.8191 78.9425 11.109 78.23C10.3989 77.5176 10 76.5514 10 75.5438C10 74.5363 10.3989 73.5701 11.109 72.8577C11.8191 72.1452 12.7822 71.745 13.7864 71.745H63.1853C64.1895 71.745 65.1526 72.1452 65.8627 72.8577C66.5728 73.5701 66.9717 74.5363 66.9717 75.5438C66.9717 76.5514 66.5728 77.5176 65.8627 78.23C65.1526 78.9425 64.1895 79.3427 63.1853 79.3427Z"
        fill="#CCCCCC"
      />
      <path
        d="M63.1853 95.7055H13.7864C12.7822 95.7055 11.8191 95.3052 11.109 94.5928C10.3989 93.8804 10 92.9142 10 91.9066C10 90.8991 10.3989 89.9329 11.109 89.2204C11.8191 88.508 12.7822 88.1078 13.7864 88.1078H63.1853C64.1895 88.1078 65.1526 88.508 65.8627 89.2204C66.5728 89.9329 66.9717 90.8991 66.9717 91.9066C66.9717 92.9142 66.5728 93.8804 65.8627 94.5928C65.1526 95.3052 64.1895 95.7055 63.1853 95.7055Z"
        fill="#CCCCCC"
      />
      <path
        d="M67.1658 112.467H17.7668C16.7626 112.467 15.7996 112.067 15.0895 111.354C14.3794 110.642 13.9805 109.676 13.9805 108.668C13.9805 107.661 14.3794 106.694 15.0895 105.982C15.7996 105.27 16.7626 104.869 17.7668 104.869H67.1658C68.17 104.869 69.1331 105.27 69.8432 105.982C70.5533 106.694 70.9522 107.661 70.9522 108.668C70.9522 109.676 70.5533 110.642 69.8432 111.354C69.1331 112.067 68.17 112.467 67.1658 112.467Z"
        fill="#CCCCCC"
      />
      <path
        d="M69.947 130.027H20.5481C19.5439 130.027 18.5808 129.627 17.8707 128.915C17.1606 128.202 16.7617 127.236 16.7617 126.228C16.7617 125.221 17.1606 124.255 17.8707 123.542C18.5808 122.83 19.5439 122.43 20.5481 122.43H69.947C70.9513 122.43 71.9143 122.83 72.6244 123.542C73.3345 124.255 73.7335 125.221 73.7335 126.228C73.7335 127.236 73.3345 128.202 72.6244 128.915C71.9143 129.627 70.9513 130.027 69.947 130.027Z"
        fill="#CCCCCC"
      />
      <path
        d="M69.9695 49.8462H34.4719C33.9744 49.8467 33.4816 49.7489 33.0217 49.5582C32.5619 49.3675 32.144 49.0878 31.792 48.735C31.44 48.3821 31.1608 47.9632 30.9702 47.502C30.7797 47.0409 30.6816 46.5466 30.6816 46.0474C30.6816 45.5482 30.7797 45.0539 30.9702 44.5927C31.1608 44.1316 31.44 43.7126 31.792 43.3598C32.144 43.007 32.5619 42.7273 33.0217 42.5366C33.4816 42.3459 33.9744 42.248 34.4719 42.2485H69.9695C70.9737 42.2485 71.9368 42.6488 72.6469 43.3612C73.3569 44.0736 73.7559 45.0399 73.7559 46.0474C73.7559 47.0549 73.3569 48.0212 72.6469 48.7336C71.9368 49.446 70.9737 49.8462 69.9695 49.8462Z"
        fill="#9C73F2"
      />
      <path
        d="M89.5344 131.416C92.3904 131.416 94.7056 129.093 94.7056 126.228C94.7056 123.363 92.3904 121.04 89.5344 121.04C86.6785 121.04 84.3633 123.363 84.3633 126.228C84.3633 129.093 86.6785 131.416 89.5344 131.416Z"
        fill="#CCCCCC"
      />
      <path
        d="M72.3338 147.188H22.9348C21.9306 147.188 20.9675 146.788 20.2574 146.075C19.5474 145.363 19.1484 144.397 19.1484 143.389C19.1484 142.382 19.5474 141.415 20.2574 140.703C20.9675 139.991 21.9306 139.59 22.9348 139.59H72.3338C73.338 139.59 74.3011 139.991 75.0111 140.703C75.7212 141.415 76.1202 142.382 76.1202 143.389C76.1202 144.397 75.7212 145.363 75.0111 146.075C74.3011 146.788 73.338 147.188 72.3338 147.188Z"
        fill="#CCCCCC"
      />
      <path
        d="M91.9251 148.577C94.781 148.577 97.0962 146.254 97.0962 143.389C97.0962 140.523 94.781 138.201 91.9251 138.201C89.0691 138.201 86.7539 140.523 86.7539 143.389C86.7539 146.254 89.0691 148.577 91.9251 148.577Z"
        fill="#CCCCCC"
      />
      <defs>
        <filter
          id="filter0_d_899_6901"
          x="0.560648"
          y="31.4574"
          width="106.328"
          height="143.422"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.43935" />
          <feGaussianBlur stdDeviation="1.21968" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_899_6901"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_899_6901"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
