export default function PhoneIcon() {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31842 12.3154C10.1699 15.781 13.739 16.1605 14.7867 16.1957C16.0529 16.2371 17.3451 15.3069 17.904 14.3542C17.0125 13.4132 15.8515 12.6831 14.5804 11.8918C13.8301 12.566 12.9056 13.8188 11.6745 13.37C10.9743 13.1167 9.24537 12.4001 7.73482 11.04C6.22326 9.68078 5.42792 8.12507 5.14444 7.49594C4.64559 6.38639 6.04195 5.55265 6.79222 4.87755C5.91274 3.71482 5.11539 2.64313 4.07162 1.88149C2.99781 2.38625 1.95805 3.53996 2.00513 4.69457C2.0442 5.63738 2.46591 8.84885 6.31842 12.3154ZM14.7126 17.9974C13.2702 17.9497 9.18226 17.4413 4.90103 13.5899C0.620792 9.73757 0.0568381 6.0601 0.00274664 4.76127C-0.0773888 2.78193 1.60746 0.859377 3.55375 0.108561C3.78812 0.0174959 4.04478 -0.0171754 4.29854 0.00794945C4.5523 0.0330743 4.79444 0.117132 5.0012 0.251874C6.61192 1.30914 7.7228 2.91083 8.67741 4.16549C8.87599 4.42638 8.96697 4.74154 8.93459 5.05641C8.90221 5.37129 8.74852 5.66607 8.50011 5.88975L7.14181 7.11287C7.45735 7.7393 8.09943 8.8191 9.15121 9.76551C10.203 10.7119 11.403 11.2897 12.1002 11.5736L13.4575 10.3514C13.707 10.1274 14.036 9.98927 14.3871 9.96111C14.7382 9.93294 15.0891 10.0165 15.3787 10.1973C16.8011 11.0842 18.472 12.0693 19.69 13.4727C19.8519 13.6601 19.9549 13.8832 19.9882 14.1187C20.0215 14.3541 19.9839 14.5933 19.8793 14.8112C19.0409 16.5715 16.9193 18.0704 14.7126 17.9974Z"
        fill="#D1D1D1"
        strokeWidth="0"
      />
    </svg>
  )
}