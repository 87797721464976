import React from "react";

export default function MessageIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.00041C0 1.34418 1.344 0 3 0H21C22.656 0 24 1.34418 24 3.00041V15.8593C24 16.6551 23.6839 17.4182 23.1213 17.9809C22.5587 18.5436 21.7957 18.8597 21 18.8597H13.8171L9.40629 23.2712C9.05666 23.6195 8.61175 23.8566 8.12763 23.9524C7.64352 24.0483 7.14186 23.9986 6.68591 23.8098C6.22996 23.6209 5.84011 23.3013 5.56551 22.8912C5.29091 22.481 5.14384 21.9988 5.14286 21.5052V18.8597H3C2.20435 18.8597 1.44129 18.5436 0.87868 17.9809C0.31607 17.4182 0 16.6551 0 15.8593V3.00041ZM3 2.57178C2.88634 2.57178 2.77733 2.61694 2.69695 2.69732C2.61658 2.77771 2.57143 2.88673 2.57143 3.00041V15.8593C2.57143 16.0959 2.76343 16.2879 3 16.2879H6.42857C6.76956 16.2879 7.09659 16.4234 7.33771 16.6646C7.57883 16.9057 7.71429 17.2328 7.71429 17.5738V21.3286L12.3771 16.6651C12.618 16.4239 12.9448 16.2882 13.2857 16.2879H21C21.1137 16.2879 21.2227 16.2428 21.303 16.1624C21.3834 16.082 21.4286 15.973 21.4286 15.8593V3.00041C21.4286 2.88673 21.3834 2.77771 21.303 2.69732C21.2227 2.61694 21.1137 2.57178 21 2.57178H3Z"
        fill="#D1D1D1"
      />
    </svg>
  );
}
