import React, { Component } from 'react'

export default class OfferIcon extends Component {
  render() {
    return (
      <svg
        width="55"
        height="55"
        viewBox="0 0 55 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="27.5" cy="27.5" r="27.5" fill="#9C73F2" />
        <path
          d="M27.5 46C25.0705 46 22.6649 45.5215 20.4204 44.5918C18.1758 43.6621 16.1364 42.2994 14.4185 40.5815C12.7006 38.8636 11.3379 36.8242 10.4082 34.5796C9.47852 32.3351 9 29.9295 9 27.5C9 25.0705 9.47852 22.6649 10.4082 20.4204C11.3379 18.1758 12.7006 16.1364 14.4185 14.4185C16.1364 12.7006 18.1758 11.3379 20.4204 10.4082C22.6649 9.47852 25.0705 9 27.5 9C32.4065 9 37.1121 10.9491 40.5815 14.4185C44.0509 17.8879 46 22.5935 46 27.5C46 32.4065 44.0509 37.1121 40.5815 40.5815C37.1121 44.0509 32.4065 46 27.5 46Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M28.2325 18.3016C28.0833 18.2415 27.9167 18.2415 27.7675 18.3016L20.3075 21.2997L23.3125 22.5068L31.005 19.4157L28.2325 18.3016ZM32.6875 20.0927L24.995 23.1838L28 24.3908L35.6925 21.2997L32.6875 20.0927ZM36.75 22.2279L28.625 25.4936V35.4439L36.75 32.1782V22.2279ZM27.375 35.4452V25.4924L19.25 22.2279V32.1795L27.375 35.4452ZM27.3038 17.1347C27.7507 16.9551 28.2493 16.9551 28.6962 17.1347L37.6075 20.7169C37.7234 20.7636 37.8227 20.844 37.8926 20.9479C37.9626 21.0518 38 21.1743 38 21.2997V32.1795C37.9998 32.4305 37.9249 32.6757 37.7847 32.8834C37.6446 33.0912 37.4457 33.252 37.2138 33.3451L28.2325 36.9549C28.0833 37.015 27.9167 37.015 27.7675 36.9549L18.7875 33.3451C18.5553 33.2522 18.3562 33.0914 18.2158 32.8837C18.0754 32.6759 18.0003 32.4306 18 32.1795V21.2997C18 21.1743 18.0374 21.0518 18.1074 20.9479C18.1773 20.844 18.2766 20.7636 18.3925 20.7169L27.3038 17.1347Z"
          fill="white"
        />
      </svg>
    )
  }
}
